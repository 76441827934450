import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, CssBaseline, Grid, Container, Typography } from "@material-ui/core";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import errorHandle from "./error_handleing.js";
import { BASE_URL, companyName } from "./config"; // Import the BASE_URL variable


window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function Home() {
  const idleTimerRef = useRef(null);
  const [perms, setPerms] = useState({
    dispatch: false,
    billing: false,
    none: false,
    admin: false,
    remittance: false,
  });
  const [displayRender, setDisplayRender] = useState("");
  const onIdle = () => {
    // post request to logout user on idle
    try {
      const location = window.location.href.split("/");
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  useEffect(() => {
    // get request to make sure user is infact logged in
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === false) {
            window.location = "/";
          } else {
            let userperms = perms;
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              userperms.admin = true;
            }
            if (
              response.data.permissions.dispatch === "true" ||
              response.data.permissions.dispatch === "1"
            ) {
              userperms.dispatch = true;
            }
            if (
              response.data.permissions.billing === "true" ||
              response.data.permissions.billing === "1"
            ) {
              userperms.billing = true;
            }
            if (
              response.data.permissions.remittance === "true" ||
              response.data.permissions.remittance === "1"
            ) {
              userperms.remittance = true;
            }
            if (
              response.data.permissions.none === "true" ||
              response.data.permissions.none === "1"
            ) {
              userperms.none = true;
            }
            if (
              response.data.permissions.payroll === "true" ||
              response.data.permissions.payroll === "1"
            ) {
              userperms.payroll = true;
            }
            setPerms(userperms);
            loadMenu();
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const loadMenu = () => {
    if (perms.none === true) {
      return "no access";
    } else if (perms.remittance === true) {
      setDisplayRender(
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div idleTimer={idleTimer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Link to="/dispatch">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Dispatch
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/invoice">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Remittance
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    } else if (perms.billing === true) {
      setDisplayRender(
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div idleTimer={idleTimer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Link to="/status">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Drivers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/billing">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Billing
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    } else if (perms.dispatch === true) {
      setDisplayRender(
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div idleTimer={idleTimer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Link to="/customer">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Customers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/status">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Drivers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/dispatch">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Dispatch
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/board">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Board
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/shippers">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Shippers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/locations">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Locations
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/ports">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Port Locations
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/ports">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Port Locations
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    } else if (perms.payroll === true) {
      setDisplayRender(
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div idleTimer={idleTimer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Link to="/dispatch">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Dispatch
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/payroll">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Payroll
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/status">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Drivers
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    } else {
      setDisplayRender("no perms");
    }
    if (perms.admin === true) {
      setDisplayRender(
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div idleTimer={idleTimer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Link to="/customer">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Customers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/status">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Drivers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/dispatch">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Dispatch
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/board">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Board
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/shippers">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Shippers
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/locations">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Locations
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/ports">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Port Locations
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/admin">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Admin
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/invoice">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Remittance
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/billing">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Billing
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/payroll">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Payroll
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div idleTimer={idleTimer}>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          {displayRender}
          <Typography variant="h1">
            {companyName}
          </Typography>
        </Grid>
      </div>
    </Container>
  );
}
