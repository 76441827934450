// config.js
export const BASE_URL = "https://dev.asayafreight.net";
export const companyName = "DEV";
export const fullCompanyName = "Capital Containers Transport LLC";
export const address = "720 Sutter Ave West Sacramento CA 95691";
export const email = "capitalcontainers@gmail.com";
export const dot = "3682624";
export const mc = "1281567";

// Capital Containers Transport LLC
// 720 Sutter Ave West Sacramento CA 95691
// capitalcontainers@gmail.com
// DOT 3682624
// MC 1281567

// LDT Transport Inc.
// 3154 SparrowDrive, Sacramento, CA, 95834
// ldt.trans@gmail.com
// USDOT 1420414
// MC 537273