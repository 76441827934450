import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Button,
  Box,
  Select,
  TextField,
  Grid,
  Typography,
  Accordion,
  FormControl,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useIdleTimer } from "react-idle-timer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import axios from "axios";
import "./Modal.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import MaterialTable from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import "./css/dispatch.css";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

export default function Dispatch(props) {
  const [containerCheck, setContainerCheck] = useState(null);
  const costAttributes = [
    "Base Rate",
    "FSC%",
    "Tolls",
    "Chasis Rental",
    "Split Chasis",
    "Chasis Gate Fee",
    "Drop",
    "Congestion",
    "Standby",
    "Storage",
    "Scale",
    "Genset",
    "PerDiem",
    "Oversize",
    "Load Gate Fee",
    "Pre Pull",
    "Stop Off",
    "Other",
  ];
  const containerAttributes = [
    "Container Number",
    "Booking Number",
    "Original Location",
    "Location In",
    "SS Line",
    "Size",
    "Availability",
    "Chasis Number",
    "Shipper Number",
    "Pin no",
    "Delivery PoNumber",
    "Weight",
    "EmptyDue",
    "ERD",
    "PortAppointment",
    "PortDate",
    "LFD",
    "Cut Off",
    "OutGate Date",
    "Delivery Date",
  ];
  const [isDropped, setIsDropped] = useState(null);
  const [outGateDate, setOutGateDate] = useState(null);
  const calculatedAttributes = ["FSC", "Total Due", "Costs", "Profit"];

  let today = new Date();
  today =
    String(today.getMonth() + 1).padStart(2, "0") +
    "/" +
    String(today.getDate()).padStart(2, "0") +
    "/" +
    today.getFullYear();
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [dispatchNumber, setDispatchNumber] = useState(null);
  const [costInformation, setCostInformation] = useState("");
  const [containerInformation, setContainerInformation] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "READY",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [customerProfile, setCustomerProfile] = useState("");
  const [consigneeProfile, setConsigneeProfile] = useState("");
  const [shipperProfile, setShipperProfile] = useState("");
  const [containerOutOBJ, setContainerOutOBJ] = useState({
    todayDate: today,
    location: "",
    driver: "",
    rate: 0,
    tolls: 0,
    extra: 0,
    fscpercent: 0,
    cheque: "",
    locationNote: "",
    driverNote: "",
  });
  const [containerInOBJ, setContainerInOBJ] = useState({
    todayDate: today,
    location: "",
    driver: "",
    rate: 0,
    tolls: 0,
    extra: 0,
    fscpercent: 0,
    cheque: "",
    locationNote: "",
    driverNote: "",
  });
  const [dropsObj, setDropsObj] = useState([
    {
      todayDate: today,
      location: "",
      locationNote: "",
      driver: "",
      driverNote: "",
      extra: 0,
      tolls: 0,
      rate: 0,
      cheque: "",
      fscpercent: 0,
    },
  ]);
  const [searchInvoiceTerm, setSearchInvoiceTerm] = useState("");
  const [searchContainerTerm, setSearchContainerTerm] = useState("");
  const [searchDispatchTerm, setSearchDispatchTerm] = useState("");
  const [searchBookingTerm, setSearchBookingTerm] = useState("");
  const [searchCustTerm, setSearchCustTerm] = useState("");
  const [searchConsTerm, setSearchConsTerm] = useState("");
  const [searchPoTerm, setSearchPoTerm] = useState("");
  const [searchChassisTerm, setSearchChassisTerm] = useState("");
  const [calculatedCosts, setCalculatedCosts] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [ports, setPorts] = useState([]);
  const [billingAttributes, setBillingAttributes] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [paidStatus, setPaidStatus] = useState(false);
  const [billedDate, setBilledDate] = useState(false);
  const [allNotes, setAllNotes] = useState([]);
  const [notes, setnotes] = useState([]);
  const [note, setNote] = useState("");
  const [modal2, setModal2] = useState(false);
  const [duplicateContainers, setDuplicateContainers] = useState([]);
  const [costsTotal, setCostsTotal] = useState(0);
  const [userID, setUserID] = useState("");
  const idleTimerRef = useRef(null);
  const [perms, setPerms] = useState(false);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const options = {
    title: "Duplicate Container",
    message:
      "Are you sure you would like to create another dispatch with this container Number",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          let todaysave = new Date();
          todaysave =
            String(todaysave.getMonth() + 1).padStart(2, "0") +
            "/" +
            String(todaysave.getDate()).padStart(2, "0") +
            "/" +
            todaysave.getFullYear();
          axios
            .post(`${BASE_URL}/dispatch/create`, {
              obj: JSON.stringify(createJSON()),
              userID: userID,
              todaysDate: new Date(todaysave),
            })
            .then((response) => {
              setDispatchNumber(response.data.check[0]);
              setInvoiceNumber(response.data.check[1]);
              alert(
                `Dispatched created\nYour Dispatch Number is: ${response.data.check[0]}\nYour Invoice Number is: ${response.data.check[1]}`
              );
            });
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlayAlert",
    class: "overlayAlert",
  };
  const options2 = {
    title: "Container Number Change",
    message:
      "Are you sure you would like to change the container Number this container number already exists in another dispatch",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          let todayupdate = new Date();
          todayupdate =
            String(todayupdate.getMonth() + 1).padStart(2, "0") +
            "/" +
            String(todayupdate.getDate()).padStart(2, "0") +
            "/" +
            todayupdate.getFullYear();
            axios
            .post(`${BASE_URL}/dispatch/update`, {
              obj: JSON.stringify(createJSON()),
              userID: userID,
              todaysDate: new Date(todayupdate),
              billedDate: billedDate,
              dispatchNumber: dispatchNumber,
            })
            .then((response) => {
              if(response.data.check === undefined) {
                alert(response.data.error);
              } else {
                alert(response.data.check);
              }
              if (props.dispatchNumber) {
                window.location.reload();
              }
            });
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlayAlert",
    class: "overlayAlert",
  };
  const options3 = {
    title: "Container Search",
    message:
      "Are you sure you would like to search any unsaved changes will be lost",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          if (searchBookingTerm !== "") {
            searchBooking();
          } else if (searchInvoiceTerm !== "") {
            searchInvoice();
          } else if (searchContainerTerm !== "") {
            searchContainer();
          } else if (searchDispatchTerm !== "") {
            searchDispatch();
          } else if (searchCustTerm !== "" || searchConsTerm !== "") {
            searchCust();
          } else if (searchPoTerm !== "") {
            searchPo();
          } else if (searchChassisTerm !== "") {
            searchChassis();
          } else {
            alert("Please enter Search term");
          }
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlayAlert",
    class: "overlayAlert",
  };
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  const toggleModal2 = () => {
    setModal2(!modal2);
  };
  const getDrivers = () => {
    axios
      .post(`${BASE_URL}/drivers`)
      .then((response) => {
        let newdrivers = [];
        //WHY DID THIS SHIT STOP WORKING
        console.dir(response)

        console.dir(response.data)
        for (let x = 0; x < response.data.check.length; x++) {
          newdrivers.push(response.data.check[x].trim().toUpperCase());
        }
        setDrivers(newdrivers);
      })
      .catch((error) => {
        console.error("Error fetching drivers:", error);
        // Handle the error appropriately, e.g., display an error message to the user.
      });
  };
  const getLocations = () => {
    axios.post(`${BASE_URL}/locations`).then((response) => {
      let newlocations = [];
      for (let x = 0; x < response.data.check.length; x++) {
        newlocations.push(response.data.check[x].toUpperCase());
      }
      setLocations(newlocations);
    });
  };
  const getPorts = () => {
    axios.post(`${BASE_URL}/getPorts`).then((response) => {
      let newlocations = [];
      for (let x = 0; x < response.data.check.length; x++) {
        newlocations.push(response.data.check[x].toUpperCase());
      }
      setPorts(newlocations);
    });
  };
  const getBillingAttributes = () => {
    axios
      .post(`${BASE_URL}/billingAttributes`)
      .then((response) => {
        let billingAttributeArray = [];
        for (let x = 0; x < response.data.check.length; x += 6) {
          let billingAttributeObject = {
            code: response.data.check[x].trim().toUpperCase(),
            name: response.data.check[x + 1].trim().toUpperCase(),
            street: response.data.check[x + 2].trim().toUpperCase(),
            city: response.data.check[x + 3].trim().toUpperCase(),
            state: response.data.check[x + 4].trim().toUpperCase(),
            zip: response.data.check[x + 5].trim().toUpperCase(),
          };
          billingAttributeArray.push(billingAttributeObject);
        }
        setBillingAttributes(billingAttributeArray);
      });
  };
  const getShippers = () => {
    axios.post(`${BASE_URL}/shippers`).then((response) => {
      let shipperArray = [];
      for (let x = 0; x < response.data.check.length; x += 6) {
        let shipperObject = {
          code: response.data.check[x].trim().toUpperCase(),
          name: response.data.check[x + 1].trim().toUpperCase(),
          street: response.data.check[x + 2].trim().toUpperCase(),
          city: response.data.check[x + 3].trim().toUpperCase(),
          state: response.data.check[x + 4].trim().toUpperCase(),
          zip: response.data.check[x + 5].trim().toUpperCase(),
        };
        shipperArray.push(shipperObject);
      }
      setShippers(shipperArray);
    });
  };
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === false) {
            window.location = "/";
          } else {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.dispatch === "true" ||
              response.data.permissions.dispatch === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.remittance === "true" ||
              response.data.permissions.remittance === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.payroll === "true" ||
              response.data.permissions.payroll === "1"
            ) {
              setPerms(true);
            }
            setUserID(response.data.userID);
            getDrivers();
            getLocations();
            getPorts();
            getBillingAttributes();
            getShippers();
            if (window.location.href.split("/").length > 4) {
              let dnumber = window.location.href.split("/");
              searchDispatchFromBoard(dnumber[4]);
            }
          }
        });
      if (props.dispatchNumber) {
        searchDispatchFromBoard(props.dispatchNumber);
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  useEffect(() => {
    try {
      calculateFSC()
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [costInformation]);
  useEffect(() => {
    try {
      calculateFSC()
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [containerOutOBJ]);
  useEffect(() => {
    try {
      calculateFSC()
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [containerInOBJ]);
  useEffect(() => {
    try {
      calculateFSC()
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [dropsObj]);
  const handleSetIsDropped = () => {
    setIsDropped(false);
  };
  const handleInDateChange = (date) => {
    let newContainerIn = containerOutOBJ;
    newContainerIn.todayDate = date;
    setContainerOutOBJ(newContainerIn);
    setIsDropped(false);
  };
  const handleDateChange = (index, date) => {
    let newNewDrops = [...dropsObj];
    newNewDrops[index].todayDate = date;
    setDropsObj(newNewDrops);
    setIsDropped(false);
  };
  const handleAttributeDateChange = (index, date) => {
    let newinfo = [...containerInformation];
    newinfo[index] = date;
    setContainerInformation(newinfo);
  };
  const handleOutGateDateChange = (date) => {
    setOutGateDate(date);
  };
  const handleOutDateChange = (date) => {
    let newContainerOut = containerInOBJ;
    newContainerOut.todayDate = date;
    setContainerInOBJ(newContainerOut);
    setIsDropped(false);
  };
  const handlebilledDateChange = (date) => {
    setBilledDate(date);
  };
  const addDrop = () => {
    setDropsObj([
      ...dropsObj,
      {
        todayDate: today,
        location: "",
        locationNote: "",
        driver: "",
        driverNote: "",
        extra: 0,
        tolls: 0,
        rate: 0,
        cheque: "",
        fscpercent: 0,
      },
    ]);
  };
  const addNote = () => {
    if (dispatchNumber === null) {
      alert("Please Create A Dispatch Before Adding A Note");
    } else if (note === "") {
      alert("Please Enter A Note");
    } else {
      let obj = {
        note: note,
        dispatchNumber: dispatchNumber,
      };
      let obj2 = {
        notes: note,
      };
      //{ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() }
      let newnotes = [...notes];
      newnotes.push(obj2);
      setnotes(newnotes);
      let newAllNote = [...allNotes];
      newAllNote.push(note);
      setAllNotes(newAllNote);
      axios
        .post(`${BASE_URL}/history`, {
          obj: JSON.stringify(obj),
        })
        .then((response) => {
          obj2.uid = response.data.uid[0]
          console.dir(response.data.uid)
          let newnotes = [...notes];
          newnotes.push(obj2);
          setnotes(newnotes);

          let newAllNote = [...allNotes];
          newAllNote.push(note);
          setAllNotes(newAllNote);
          document.getElementById("note").value = "";
        });
    }
  };
  const removeDrop = (index) => {
    let newdrops = [...dropsObj];
    newdrops.splice(index, 1);
    setDropsObj(newdrops);
  };
  const save = () => {
    let dispatchIfo = createJSONsave();
    console.dir(dispatchIfo)
    let todaysave = new Date();
    todaysave =
      String(todaysave.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(todaysave.getDate()).padStart(2, "0") +
      "/" +
      todaysave.getFullYear();
    if (dispatchIfo.custcode === undefined || dispatchIfo.custcode === "") {
      alert("Please enter a customer code");
    } else if (
      dispatchIfo.ContainerNumber === "" &&
      dispatchIfo.BookingNumber === ""
    ) {
      alert("Please enter a container number or booking number");
      document.getElementById("Container Number").focus();
    } else if (
      dispatchIfo.OriginalLocation === undefined ||
      dispatchIfo.OriginalLocation === ""
    ) {
      alert("Please enter an original location");
    } else if (dispatchIfo.SSLine === undefined || dispatchIfo.SSLine === "") {
      alert("Please enter a shipper");
      document.getElementById("SS Line").focus();
    } else {
      axios
        .post(`${BASE_URL}/dispatch/checkContainer`, {
          obj: containerInformation[0],
          userID: userID,
          todaysDate: new Date(todaysave),
        })
        .then((response) => {
          if (response.data.check === "duplicate") {
            confirmAlert(options);
          } else {
            axios
              .post(`${BASE_URL}/dispatch/create`, {
                obj: JSON.stringify(createJSONsave()),
                userID: userID,
                todaysDate: new Date(todaysave),
              })
              .then((response) => {
                setDispatchNumber(response.data.check[0]);
                setInvoiceNumber(response.data.check[1]);
                alert(
                  `Dispatched created\nYour Dispatch Number is: ${response.data.check[0]}\nYour Invoice Number is: ${response.data.check[1]}`
                );
              });
          }
        });
    }
  };
  const update = () => {
    if(containerCheck === containerInformation[0]){
      if (dispatchNumber === null) {
        save();
      } else {
        let todayupdate = new Date();
        todayupdate =
          String(todayupdate.getMonth() + 1).padStart(2, "0") +
          "/" +
          String(todayupdate.getDate()).padStart(2, "0") +
          "/" +
          todayupdate.getFullYear();
          console.dir(JSON.stringify(createJSON()))
        axios
          .post(`${BASE_URL}/dispatch/update`, {
            obj: JSON.stringify(createJSON()),
            userID: userID,
            todaysDate: new Date(todayupdate),
            billedDate: billedDate,
            dispatchNumber: dispatchNumber,
          })
          .then((response) => {
            if(response.data.check === undefined) {
              alert(response.data.error);
            } else {
              alert(response.data.check);
            }
            if (props.dispatchNumber) {
              window.location.reload();
            }
          });
      }
    } else {
      if (dispatchNumber === null) {
        save();
      } else {
      axios
      .post(`${BASE_URL}/dispatch/duplicatecontainercheck`, {
        dispatchNumber: dispatchNumber,
        containerNumber: containerInformation[0]
      })
      .then((response) => {
        if (response.data.check) {

          confirmAlert(options2);
        } else {
          let todayupdate = new Date();
        todayupdate =
          String(todayupdate.getMonth() + 1).padStart(2, "0") +
          "/" +
          String(todayupdate.getDate()).padStart(2, "0") +
          "/" +
          todayupdate.getFullYear();
          axios
          .post(`${BASE_URL}/dispatch/update`, {
            obj: JSON.stringify(createJSON()),
            userID: userID,
            todaysDate: new Date(todayupdate),
            billedDate: billedDate,
            dispatchNumber: dispatchNumber,
          })
          .then((response) => {
            if(response.data.check === undefined) {
              alert(response.data.error);
            } else {
              alert(response.data.check);
            }
            if (props.dispatchNumber) {
              window.location.reload();
            }
          });
        }
      });
    }
    }
  };
  const toInt = (number) => {
    const parsed = parseFloat(number);
    if (isNaN(parsed)) {
        return "0.00"; // Return a string with two decimal places
    }
    return parsed.toFixed(2);
  };
  const toFloatWithTwoDecimalPlaces = (number) => {
    const parsed = parseFloat(number);
    if (isNaN(parsed)) {
      return 0;
    }
    return parseFloat(parsed.toFixed(2));
  };
  const checkNumber = (number) => {
    if (isNaN(number) || number === "") {
      return 0;
    } else {
      console.log(number)
      return number;
    }
  };
  const searchInvoice = () => {
    try {
      if (searchInvoiceTerm !== "") {
        let todaysearchInvoice = new Date();
        todaysearchInvoice =
          String(todaysearchInvoice.getMonth() + 1).padStart(2, "0") +
          "/" +
          String(todaysearchInvoice.getDate()).padStart(2, "0") +
          "/" +
          todaysearchInvoice.getFullYear();
        axios
          .post(`${BASE_URL}/dispatch/searchInvoice`, {
            search: searchInvoiceTerm,
            userID: userID,
            todaysDate: new Date(todaysearchInvoice),
          })
          .then((response) => {
            getDrivers();
            getLocations();
            getBillingAttributes();
            getShippers();
            document.getElementById("searchTermInvoice").value = "";
            if (response.data.check !== `invoice does not exist`) {
              setContainerOutOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setContainerInOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setOutGateDate(null)
              setDropsObj([]);
              setSearchInvoiceTerm("");
              setIsDropped(response.data.isDropped);
              console.log("outgatedate -> ", response.data.outGateDate)
              if (response.data.outGateDate !== "" && response.data.outGateDate !== null) {
                setOutGateDate(formatOutGateDate(response.data.outGateDate));
                console.log("outgatedate -> ", formatOutGateDate(response.data.outGateDate))
              }
              setAllNotes(response.data.notes);
              let allNotes = [];
              if(response.data.notes != undefined){
                for (let x = 0; x < response.data.notes.length; x+=2) {
                  allNotes.push({ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() });
                }
              }
              setnotes(allNotes);
              parseSearchResults(response.data.drop, response.data.check);
            } else {
              axios
                .post(
                  `${BASE_URL}/dispatch/getDispatchFromOld`,
                  {
                    number: searchInvoiceTerm,
                    userID: userID,
                    todaysDate: new Date(todaysearchInvoice),
                  }
                )
                .then((response) => {
                  if (response.data.check === true) {
                    searchInvoice();
                  } else {
                    alert("invoice does not exist");
                  }
                });
            }
          });
      } else {
        alert(`please enter a search term`);
        document.getElementById("searchTermInvoice").focus();
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const handlegetoutgatedate = (date) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (date !== undefined && String(date).length > 10) {
      let splitDate = String(date).split("-");
      let secondsplit = String(splitDate[2]).split("T");
      return (
        months[Number(splitDate[1]) - 1] +
        " " +
        secondsplit[0] +
        " " +
        splitDate[0]
      );
    } else {
      return null;
    }
  };
  const searchContainer = () => {
    try {
      if (searchContainerTerm !== "") {
        axios
          .post(`${BASE_URL}/duplicatescheck/`, {
            search: searchContainerTerm.trim(),
          })
          .then((response) => {
            if (response.data.check > 1) {
              axios
                .post(`${BASE_URL}/handleduplicates/`, {
                  search: searchContainerTerm.trim(),
                })
                .then((response) => {
                  let containerOBJArray = [];
                  for (let x = 0; x < response.data.duplicates.length; x += 5) {
                    let containerOBJ = {
                      dispatchNumber: response.data.duplicates[x],
                      containerNumber: response.data.duplicates[x + 1],
                      customer: response.data.duplicates[x + 2],
                      consignee: response.data.duplicates[x + 3],
                      booking: "",
                      invoiceNumber: response.data.duplicates[x + 4]
                    };
                    containerOBJArray.push(containerOBJ);
                  }
                  let newDuplicateContainers = [...duplicateContainers];
                  newDuplicateContainers = containerOBJArray;
                  setDuplicateContainers(newDuplicateContainers);
                  toggleModal2();
                });
            } else {
              if (searchContainerTerm !== "") {
                let todaysearchContainer = new Date();
                todaysearchContainer =
                  String(todaysearchContainer.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  String(todaysearchContainer.getDate()).padStart(2, "0") +
                  "/" +
                  todaysearchContainer.getFullYear();
                axios
                  .post(`${BASE_URL}/container/`, {
                    search: searchContainerTerm.trim(),
                    userID: userID,
                    todaysDate: new Date(todaysearchContainer),
                  })
                  .then((response) => {
                    getDrivers();
                    getLocations();
                    getBillingAttributes();
                    getShippers();
                    document.getElementById("searchTermContainer").value = "";
                    if (response.data.check !== `container does not exist`) {
                      setContainerOutOBJ({
                        todayDate: today,
                        location: "",
                        driver: "",
                        rate: 0,
                        tolls: 0,
                        extra: 0,
                        fscpercent: 0,
                        cheque: "",
                        locationNote: "",
                        driverNote: "",
                      });
                      setContainerInOBJ({
                        todayDate: today,
                        location: "",
                        driver: "",
                        rate: 0,
                        tolls: 0,
                        extra: 0,
                        fscpercent: 0,
                        cheque: "",
                        locationNote: "",
                        driverNote: "",
                      });
                      setOutGateDate(null)
                      setDropsObj([]);
                      setSearchContainerTerm("");
                      setIsDropped(response.data.isDropped);
                      console.log("outgatedate -> ", response.data.outGateDate)
                      if (response.data.outGateDate !== "" && response.data.outGateDate !== null) {
                        setOutGateDate(formatOutGateDate(response.data.outGateDate));
                        console.log("outgatedate -> ", formatOutGateDate(response.data.outGateDate))
                      }
                      // let allNotes = [];
                      // console.dir(response.data.notes)
                      // // if(response.data.notes != undefined){
                      //   for (let x = 0; x < response.data.notes.length; x+=2) {
                      //     allNotes.push({ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() });
                      //   }
                      // // }
                      // setnotes(allNotes);
                      setAllNotes(response.data.notes);
                      let allNotes = [];
                      if(response.data.notes != undefined){
                        for (let x = 0; x < response.data.notes.length; x+=2) {
                          allNotes.push({ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() });
                        }
                      }
                      setnotes(allNotes);
                      parseSearchResults(response.data.drop, response.data.check);
                    } else {
                      alert(response.data.check);
                    }
                  });
              } else {
                alert(`please enter a search term`);
                document.getElementById("searchTermContainer").focus();
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchDispatch = () => {
    try {
      if (searchDispatchTerm !== "") {
        axios
          .post(`${BASE_URL}/dispatch/searchDispatch`, {
            search: searchDispatchTerm,
          })
          .then((response) => {
            getDrivers();
            getLocations();
            getBillingAttributes();
            getShippers();
            document.getElementById("searchTermDispatch").value = "";
            if (response.data.check !== `dispatch does not exist`) {
              setContainerOutOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setContainerInOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setDropsObj([]);
              setSearchDispatchTerm("");
              setIsDropped(response.data.isDropped);
              setOutGateDate(null)

              if (response.data.outGateDate[0] !== "") {
                setOutGateDate(
                  formatOutGateDate(response.data.outGateDate[0])
                );
              }
              setAllNotes(response.data.notes);
              let allNotes = [];
              console.dir(response.data.notes)
              console.log("response.data.notes.length -> ", response.data.notes.length)
              for (let x = 0; x < response.data.notes.length; x+=2) {
                allNotes.push({ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() });
              }
              setnotes(allNotes);
              parseSearchResults(response.data.drop, response.data.check);
            } else {
              alert(response.data.check);
            }
          });
      } else {
        alert(`please enter a search term`);
        document.getElementById("searchTermDispatch").focus();
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const parseSearchResults = (dropsArray, informationArray) => {
    console.log(informationArray[63])
    if (informationArray[63] === "true" || informationArray[63] === true || informationArray[63] === 1) {
      setPaidStatus(true);
      document.getElementById("paidStatus").checked = true;
    } else {
      setPaidStatus(false);
      document.getElementById("paidStatus").checked = false;
    }
    setBilledDate(informationArray[64]);
    if (informationArray[61] === "") {
      setInvoiceNumber(informationArray[60]);
    } else {
      setInvoiceNumber(informationArray[61]);
    }
    setDispatchNumber(informationArray[62]);
    setCustomerProfile([
      informationArray[66].trim().toUpperCase(),
      informationArray[67].trim().toUpperCase(),
      `${informationArray[68]}, ${informationArray[69]}, ${informationArray[71]}`,
    ]);
    setConsigneeProfile([
      informationArray[79].trim().toUpperCase(),
      informationArray[80].trim().toUpperCase(),
      `${informationArray[81]}, ${informationArray[82]}, ${informationArray[84]}`,
    ]);
    setShipperProfile([
      informationArray[92].trim().toUpperCase(),
      informationArray[93].trim().toUpperCase(),
      `${informationArray[94]}, ${informationArray[95]}, ${informationArray[97]}`,
    ]);
    let parsedsplitportDate = formatPortDate(informationArray[16]);
    if (
      parsedsplitportDate === null ||
      parsedsplitportDate === "undefined undefined undefined" || parsedsplitportDate === "undefined/undefined/"
    ) {
      parsedsplitportDate = "";
    }
    let parsedLFD = formatPortDate(informationArray[13]);
    if (parsedLFD === null || parsedLFD === "undefined undefined undefined" || parsedLFD === "undefined/undefined/") {
      parsedLFD = "";
    }
    let parsedcutoff = formatPortDate(informationArray[12]);
    if (parsedcutoff === null || parsedcutoff === "undefined undefined undefined" || parsedcutoff === "undefined/undefined/"
    ) {
      parsedcutoff = "";
    }
    let parsederd = formatPortDate(informationArray[11]);
    if (parsederd === null || parsederd === "undefined undefined undefined" || parsederd === "undefined/undefined/") {
      parsederd = "";
    }
    let parsedemptydue = formatPortDate(informationArray[17]);
    if (parsedemptydue === null || parsedemptydue === "undefined undefined undefined" || parsedemptydue === "undefined/undefined/") {
      parsedemptydue = "";
    }
    setContainerCheck(informationArray[6].trim().toUpperCase())
    let formattedDateTime = ''
    if (informationArray[14] !== undefined && String(informationArray[14]).length > 10) {
      let dateTime = new Date(String(informationArray[14]));
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1; 
      let day = dateTime.getDate();
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      let amOrPm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      formattedDateTime = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;
    }
    setContainerInformation([
      informationArray[6].trim().toUpperCase(), // Container Number
      informationArray[5].trim().toUpperCase(), // Booking Number
      informationArray[18].trim().toUpperCase(), // Original Location
      informationArray[19].trim().toUpperCase(), // Location In
      informationArray[4].trim().toUpperCase(), // SS Line
      informationArray[54].trim().toUpperCase(), // Size
      informationArray[59].trim().toUpperCase(), // Availability
      informationArray[7].trim().toUpperCase(), // Chasis Number
      informationArray[52].trim().toUpperCase(), // Shipper Number
      informationArray[10].trim().toUpperCase(), // Pin No
      informationArray[58].trim().toUpperCase(), // Delivery PoNumber
      informationArray[55], // Weight
      parsedemptydue, // Empty Due
      parsederd, // ERD
      informationArray[15].trim().toUpperCase(), // Port Appointment
      parsedsplitportDate, // Port Date
      parsedLFD, // LFD
      parsedcutoff, // Cut Off
      formattedDateTime, // Delivery Date
    ]);
    let newContainerOutOBJ = containerOutOBJ;
    newContainerOutOBJ.todayDate = dropsArray[2];
    newContainerOutOBJ.location = dropsArray[3].trim().toUpperCase();
    newContainerOutOBJ.driver = dropsArray[5].trim().toUpperCase();
    newContainerOutOBJ.rate = dropsArray[9];
    newContainerOutOBJ.tolls = dropsArray[8];
    newContainerOutOBJ.extra = dropsArray[7];
    newContainerOutOBJ.fscpercent = dropsArray[14];
    newContainerOutOBJ.cheque = dropsArray[10];
    newContainerOutOBJ.locationNote = dropsArray[4].trim().toUpperCase();
    newContainerOutOBJ.driverNote = dropsArray[6].trim().toUpperCase();
    setContainerOutOBJ(newContainerOutOBJ);
    let newContainerInOBJ = {
      todayDate: today,
      location: "",
      driver: "",
      rate: 0,
      tolls: 0,
      extra: 0,
      fscpercent: 0,
      cheque: "",
      locationNote: "",
      driverNote: "",
    };
    let newDropsArray = dropsObj;
    newDropsArray = [];
    let newDropsObj = {};
    let dropsCost = 0;
    for (let x = 15; x < dropsArray.length; x += 15) {
      if (dropsArray[x + 2] !== "") {
        newDropsObj = {};
        if (dropsArray[x + 11] === "true" || dropsArray[x + 11] === true) {
          newContainerInOBJ.todayDate = dropsArray[x + 2];
          newContainerInOBJ.location = dropsArray[x + 3].trim().toUpperCase();
          newContainerInOBJ.driver = dropsArray[x + 5].trim().toUpperCase();
          newContainerInOBJ.rate = dropsArray[x + 9];
          newContainerInOBJ.tolls = dropsArray[x + 8];
          newContainerInOBJ.extra = dropsArray[x + 7];
          newContainerInOBJ.fscpercent = dropsArray[x + 14];
          newContainerInOBJ.cheque = dropsArray[x + 10];
          newContainerInOBJ.locationNote = dropsArray[x + 4]
            .trim()
            .toUpperCase();
          newContainerInOBJ.driverNote = dropsArray[x + 6].trim().toUpperCase();
        } else {
          newDropsObj = {
            todayDate: dropsArray[x + 2],
            location: dropsArray[x + 3].trim().toUpperCase(),
            locationNote: dropsArray[x + 4].trim().toLowerCase(),
            driver: dropsArray[x + 5].trim().toUpperCase(),
            driverNote: dropsArray[x + 6].trim().toLowerCase(),
            extra: dropsArray[x + 7],
            tolls: dropsArray[x + 8],
            rate: dropsArray[x + 9],
            cheque: dropsArray[x + 10],
            fscpercent: dropsArray[x + 14],
          };
          dropsCost +=
          parseFloat(toInt(dropsArray[x + 7])) +
          parseFloat(toInt(dropsArray[x + 8])) +
          parseFloat(toInt(dropsArray[x + 9])) +
          parseFloat(toInt(dropsArray[x + 9])) * (parseFloat(toInt(dropsArray[x + 14])) / 100);
          newDropsArray.push(newDropsObj);
        }
      }
    }
    setContainerInOBJ(newContainerInOBJ);
    setDropsObj(newDropsArray);
    dropsCost +=
    parseFloat(toInt(containerOutOBJ.rate)) +
    parseFloat(toInt(containerOutOBJ.tolls)) +
    parseFloat(toInt(containerOutOBJ.extra)) +
    parseFloat(toInt(containerOutOBJ.rate)) * (parseFloat(toInt(containerOutOBJ.fscpercent)) / 100) +
    parseFloat(toInt(containerInOBJ.rate)) +
    parseFloat(toInt(containerInOBJ.tolls)) +
    parseFloat(toInt(containerInOBJ.extra)) +
    parseFloat(toInt(containerInOBJ.rate)) * (parseFloat(toInt(containerInOBJ.fscpercent)) / 100);
    let newcostInformation = [...costInformation];
    newcostInformation = [
      informationArray[28],
      informationArray[29],
      informationArray[33],
      informationArray[32],
      informationArray[38],
      informationArray[35],
      informationArray[34],
      informationArray[36],
      informationArray[31],
      informationArray[37],
      informationArray[39],
      informationArray[42],
      informationArray[40],
      informationArray[41],
      informationArray[43],
      informationArray[44],
      informationArray[45],
      informationArray[46],
    ];
    setCostInformation(newcostInformation);
  };
  const searchBooking = () => {
    try {
      if (searchBookingTerm !== "") {
        axios
          .post(`${BASE_URL}/searchbooking/Booking`, {
            search: searchBookingTerm.trim(),
          })
          .then((response) => {
            if (response.data.check >= 1) {
              axios
                .post(
                  `${BASE_URL}/handleduplicates/bookings`,
                  {
                    search: searchBookingTerm.trim(),
                  }
                )
                .then((response) => {
                  let containerOBJArray = [];
                  for (let x = 0; x < response.data.duplicates.length; x += 6) {
                    let containerOBJ = {
                      dispatchNumber: response.data.duplicates[x],
                      containerNumber: response.data.duplicates[x + 1],
                      customer: response.data.duplicates[x + 2],
                      consignee: response.data.duplicates[x + 3],
                      booking: response.data.duplicates[x + 4],
                      invoiceNumber: response.data.duplicates[x + 5]
                    };
                    containerOBJArray.push(containerOBJ);
                  }
                  let newDuplicateContainers = [...duplicateContainers];
                  newDuplicateContainers = containerOBJArray;
                  setDuplicateContainers(newDuplicateContainers);
                  toggleModal2();
                });
            } else {
              if (searchBookingTerm !== "") {
                let todaysearchContainer = new Date();
                todaysearchContainer =
                  String(todaysearchContainer.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  String(todaysearchContainer.getDate()).padStart(2, "0") +
                  "/" +
                  todaysearchContainer.getFullYear();
                axios
                  .post(
                    `${BASE_URL}/searchbooking/search`,
                    {
                      search: searchBookingTerm.trim(),
                    }
                  )
                  .then((response) => {
                    searchDispatchFromBoard(response.data.check);
                  });
              } else {
                alert(`please enter a search term`);
                document.getElementById("searchTermContainer").focus();
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchCust = () => {
    try {
      if (searchCustTerm !== "" || searchConsTerm !== "") {
        axios
          .post(`${BASE_URL}/duplicatescheckcust`, {
            search: searchCustTerm.trim(),
            search2: searchConsTerm.trim(),
          })
          .then((response) => {
            if (response.data.check >= 1) {
              axios
                .post(`${BASE_URL}/handleduplicates/cust`, {
                  search: searchCustTerm.trim(),
                  search2: searchConsTerm.trim(),
                })
                .then((response) => {
                  let containerOBJArray = [];
                  for (let x = 0; x < response.data.duplicates.length; x += 5) {
                    let containerOBJ = {
                      dispatchNumber: response.data.duplicates[x],
                      containerNumber: response.data.duplicates[x + 1],
                      customer: response.data.duplicates[x + 2],
                      consignee: response.data.duplicates[x + 3],
                      booking: "",
                      invoiceNumber: response.data.duplicates[x + 4]
                    };
                    containerOBJArray.push(containerOBJ);
                  }
                  let newDuplicateContainers = [...duplicateContainers];
                  newDuplicateContainers = containerOBJArray;
                  setDuplicateContainers(newDuplicateContainers);
                  toggleModal2();
                });
            } else {
              if (searchCustTerm !== "" || searchConsTerm !== "") {
                let todaysearchContainer = new Date();
                todaysearchContainer =
                  String(todaysearchContainer.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  String(todaysearchContainer.getDate()).padStart(2, "0") +
                  "/" +
                  todaysearchContainer.getFullYear();
                axios
                  .post(
                    `${BASE_URL}/searchbooking/search`,
                    {
                      search: searchCustTerm.trim(),
                      search2: searchConsTerm.trim(),
                    }
                  )
                  .then((response) => {
                    searchDispatchFromBoard(response.data.check);
                  });
              } else {
                alert(`please enter a search term`);
                document.getElementById("searchTermContainer").focus();
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchPo = () => {
    try {
      if (searchPoTerm !== "") {
        axios
          .post(`${BASE_URL}/duplicatescheckpo`, {
            search: searchPoTerm.trim(),
          })
          .then((response) => {
            if (response.data.check >= 1) {
              axios
                .post(`${BASE_URL}/handleduplicates/po`, {
                  search: searchPoTerm.trim(),
                })
                .then((response) => {
                  let containerOBJArray = [];
                  for (let x = 0; x < response.data.duplicates.length; x += 5) {
                    let containerOBJ = {
                      dispatchNumber: response.data.duplicates[x],
                      containerNumber: response.data.duplicates[x + 1],
                      customer: response.data.duplicates[x + 2],
                      consignee: response.data.duplicates[x + 3],
                      booking: "",
                      invoiceNumber: response.data.duplicates[x + 4]
                    };
                    containerOBJArray.push(containerOBJ);
                  }
                  let newDuplicateContainers = [...duplicateContainers];
                  newDuplicateContainers = containerOBJArray;
                  setDuplicateContainers(newDuplicateContainers);
                  toggleModal2();
                });
            } else {
              if (searchPoTerm !== "") {
                let todaysearchContainer = new Date();
                todaysearchContainer =
                  String(todaysearchContainer.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  String(todaysearchContainer.getDate()).padStart(2, "0") +
                  "/" +
                  todaysearchContainer.getFullYear();
                axios
                  .post(`${BASE_URL}/searchbooking/po`, {
                    search: searchPoTerm.trim(),
                  })
                  .then((response) => {
                    searchDispatchFromBoard(response.data.check);
                  });
              } else {
                alert(`please enter a search term`);
                document.getElementById("searchTermContainer").focus();
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchChassis = () => {
    try {
      if (searchChassisTerm !== "") {
        axios
          .post(`${BASE_URL}/duplicatescheckchassis`, {
            search: searchChassisTerm.trim(),
          })
          .then((response) => {
            if (response.data.check >= 1) {
              axios
                .post(`${BASE_URL}/handleduplicates/chassis`, {
                  search: searchChassisTerm.trim(),
                })
                .then((response) => {
                  let containerOBJArray = [];
                  for (let x = 0; x < response.data.duplicates.length; x += 6) {
                    let containerOBJ = {
                      dispatchNumber: response.data.duplicates[x],
                      containerNumber: response.data.duplicates[x + 1],
                      customer: response.data.duplicates[x + 2],
                      consignee: response.data.duplicates[x + 3],
                      booking: "",
                      invoiceNumber: response.data.duplicates[x + 4],
                      chassis: response.data.duplicates[x + 5]
                    };
                    containerOBJArray.push(containerOBJ);
                  }
                  let newDuplicateContainers = [...duplicateContainers];
                  newDuplicateContainers = containerOBJArray;
                  setDuplicateContainers(newDuplicateContainers);
                  toggleModal2();
                });
            } else {
              if (searchChassisTerm !== "") {
                let todaysearchContainer = new Date();
                todaysearchContainer =
                  String(todaysearchContainer.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  String(todaysearchContainer.getDate()).padStart(2, "0") +
                  "/" +
                  todaysearchContainer.getFullYear();
                axios
                  .post(`${BASE_URL}/searchbooking/po`, {
                    search: searchChassisTerm.trim(),
                  })
                  .then((response) => {
                    searchDispatchFromBoard(response.data.check);
                  });
              } else {
                alert(`please enter a search term`);
                document.getElementById("searchTermContainer").focus();
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchAll = () => {
    try {
      confirmAlert(options3);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchDispatchFromBoard = (dispatchNumber) => {
    try {
      if (dispatchNumber !== "") {
        axios
          .post(`${BASE_URL}/dispatch/searchDispatch`, {
            search: dispatchNumber.trim(),
          })
          .then((response) => {
            getDrivers();
            getLocations();
            getBillingAttributes();
            getShippers();
            document.getElementById("searchTermDispatch").value = "";
            if (response.data.check !== `dispatch does not exist`) {
              setContainerOutOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setContainerInOBJ({
                todayDate: today,
                location: "",
                driver: "",
                rate: 0,
                tolls: 0,
                extra: 0,
                fscpercent: 0,
                cheque: "",
                locationNote: "",
                driverNote: "",
              });
              setDropsObj([]);
              setSearchDispatchTerm("");
              setIsDropped(response.data.isDropped);
              setOutGateDate(null)

              if (response.data.outGateDate[0] !== "") {
                setOutGateDate(formatOutGateDate(response.data.outGateDate[0]));
              }
              setAllNotes(response.data.notes);
              let allNotes = [];
              if(response.data.notes != undefined){
                for (let x = 0; x < response.data.notes.length; x+=2) {
                  allNotes.push({ uid: response.data.notes[x].trim(), notes: response.data.notes[x+1].trim() });
                }
              }
              setnotes(allNotes);
              parseSearchResults(response.data.drop, response.data.check);
            } else {
              alert(response.data.check);
            }
          });
      } else {
        alert(`please enter a search term`);
        document.getElementById("searchTermDispatch").focus();
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const lastMap = (index) => {
    if (dropsObj.length - 1 === index) {
      return (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={addDrop}
          margin="normal"
          style={{ width: "25%", fontSize: 10, backgroundColor: "red" }}
        >
          Add Drop
        </Button>
      );
    }
  };
  const firstMap = (index) => {
    if (dropsObj.length - 1 === index) {
      return (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => removeDrop(index)}
          margin="normal"
          style={{ width: "25%", fontSize: 10 }}
        >
          Remove Drop
        </Button>
      );
    }
  };
  const formatOutGateDate = (date) => {
    let splitTest = String(date).split(" ")
    //2023-07-17T07:00:00.000Z
    if(splitTest.length > 6 ){
      return date
    }
    if (
      date !== undefined &&
      String(date).length > 10
    ) {
      let splitDate = String(date).split("T");
      let splitDate2 = String(splitDate[0]).split("-");
      return splitDate2[1] + "/" + splitDate2[2] + "/" + splitDate2[0];

    } else {
      return null;
    }
  };
  const formatPortDate = (date) => {
    const monthMap = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    let splitTest = String(date).split(" ")
    //2023-07-17T07:00:00.000Z
    if(splitTest.length > 6 ){
      return monthMap[splitTest[1]] + "/" + splitTest[2] + "/" + splitTest[3];
    }
    if (
      date !== undefined &&
      String(date).length > 10
    ) {
      let splitDate = String(date).split("T");
      let splitDate2 = String(splitDate[0]).split("-");
      return splitDate2[1] + "/" + splitDate2[2] + "/" + splitDate2[0];

    } else {
      return null;
    }
  };
  const formatDate = (date) => {
    let splitTest = String(date).split(" ")
    //2023-07-17T07:00:00.000Z
    if(splitTest.length > 6 || (splitTest.length === 1 && String(date).split("T").length === 2)){
      return date
    }
    if (
      date !== undefined &&
      String(date).length > 10 && splitTest.length < 6
    ) {
      let splitDate = String(date).split("T");
      let splitDate2 = String(splitDate).split("-");
      return splitDate2[1] + "/" + splitDate2[2] + "/" + splitDate2[0];

    } else {
      return null;
    }
  };
  const createJSON = () => {
    if (containerInformation[17] !== null) {
      if (containerInformation[17].length < 10) {
        containerInformation[17] = null;
      }
    }
    if (containerInformation[16] !== null) {
      if (containerInformation[16].length < 10) {
        containerInformation[16] = null;
      }
    }
    if (containerInformation[15] !== null) {
      if (containerInformation[15].length < 10) {
        containerInformation[15] = null;
      }
    }
    if (containerInformation[13] !== null) {
      if (containerInformation[13].length < 10) {
        containerInformation[13] = null;
      }
    }
    if (containerInformation[12] !== null) {
      if (containerInformation[12].length < 10) {
        containerInformation[12] = null;
      }
    }
    let newOBJ = {
      invoiceNumber: invoiceNumber,
      dispatchNumber: dispatchNumber,
      custcode: customerProfile[0],
      shippercode: shipperProfile[0],
      consigneecode: consigneeProfile[0],
      ContainerNumber: containerInformation[0],
      BookingNumber: containerInformation[1],
      OriginalLocation: containerInformation[2],
      Location_In: containerInformation[3],
      SSLine: containerInformation[4],
      Size: containerInformation[5],
      Status: containerInformation[6],
      chassisNumber: containerInformation[7],
      ShipperNumber: containerInformation[8],
      PinNumber: containerInformation[9],
      ReferencePoNumber: containerInformation[10],
      Weight: containerInformation[11],
      MTDue: containerInformation[12],
      ERD: containerInformation[13],
      PortAppointment: containerInformation[14],
      PortDate: containerInformation[15],
      LFD: containerInformation[16],
      CutOff: containerInformation[17],
      deliveryDate: containerInformation[18],
      Pieces: "",
      TerminalOut: "",
      isDropped: isDropped,
      OutGateDate: outGateDate,
      charges: checkNumber(costInformation[0]),
      fscPercentage: checkNumber(costInformation[1]),
      Tolls: checkNumber(costInformation[2]),
      chassisrental: checkNumber(costInformation[3]),
      SplitChassis: checkNumber(costInformation[4]),
      GateFee: checkNumber(costInformation[5]),
      Drop: checkNumber(costInformation[6]),
      Congestion: checkNumber(costInformation[7]),
      standby: checkNumber(costInformation[8]),
      Storage: checkNumber(costInformation[9]),
      Scale: checkNumber(costInformation[10]),
      Genset: checkNumber(costInformation[11]),
      PerDiem: checkNumber(costInformation[12]),
      OverSize: checkNumber(costInformation[13]),
      LoadGateFee: checkNumber(costInformation[14]),
      PrePull: checkNumber(costInformation[15]),
      StopOff: checkNumber(costInformation[16]),
      Other: checkNumber(costInformation[17]),
      fsc: calculatedCosts[0],
      TotalDue: calculatedCosts[1],
      costs: calculatedCosts[2],
      profit: calculatedCosts[3],
      drops: [
        {
          Date: formatDate(containerOutOBJ.todayDate),
          Extra: toInt(containerOutOBJ.extra),
          Tolls: toInt(containerOutOBJ.tolls),
          Rate: toInt(containerOutOBJ.rate),
          check: containerOutOBJ.cheque,
          Dest: containerOutOBJ.location.trim(),
          Destnote: containerOutOBJ.locationNote,
          Driver: containerOutOBJ.driver,
          Drivernote: containerOutOBJ.driverNote,
          In: 0,
          Out: 1,
          DropNumber: 0,
          Dropfsc: toInt(containerOutOBJ.fscpercent),
        }
        
      ],
    };
    for (let x = 0; x <= dropsObj.length; x++) {
      if(x === dropsObj.length){
        newOBJ.drops.push(
          {
            ["Date"]: formatDate(containerInOBJ.todayDate),
            ["Extra"]: toInt(containerInOBJ.extra),
            ["Tolls"]: toInt(containerInOBJ.tolls),
            ["Rate"]: toInt(containerInOBJ.rate),
            ["check"]: containerInOBJ.cheque,
            ["Dest"]: containerInOBJ.location.trim(),
            ["Destnote"]: containerInOBJ.locationNote.replace(/'/g, "''"),
            ["Driver"]: containerInOBJ.driver,
            ["Drivernote"]: containerInOBJ.driverNote.replace(/'/g, "''"),
            ["In"]: 1,
            ["Out"]: 0,
            ["DropNumber"]: (x + 1),
            ["Dropfsc"]: toInt(containerInOBJ.fscpercent),
          }
        )
      } else {
        newOBJ.drops.push(
          {
            ["Date"]: formatDate(dropsObj[x].todayDate),
            ["Extra"]: toInt(dropsObj[x].extra),
            ["Tolls"]: toInt(dropsObj[x].tolls),
            ["Rate"]: toInt(dropsObj[x].rate),
            ["check"]: dropsObj[x].cheque,
            ["Dest"]: dropsObj[x].location.trim(),
            ["Destnote"]: dropsObj[x].locationNote.replace(/'/g, "''"),
            ["Driver"]: dropsObj[x].driver,
            ["Drivernote"]: dropsObj[x].driverNote.replace(/'/g, "''"),
            ["In"]: 0,
            ["Out"]: 0,
            ["DropNumber"]: (x + 1),
            ["Dropfsc"]: toInt(dropsObj[x].fscpercent),
          }
        )
      }
    }
    return newOBJ;
  };
  const createJSONsave = () => {
    if (containerInformation[17] !== null) {
      if (containerInformation[17].length < 10) {
        containerInformation[17] = null;
      }
    }
    if (containerInformation[16] !== null) {
      if (containerInformation[16].length < 10) {
        containerInformation[16] = null;
      }
    }
    if (containerInformation[15] !== null) {
      if (containerInformation[15].length < 10) {
        containerInformation[15] = null;
      }
    }
    if (containerInformation[13] !== null) {
      if (containerInformation[13].length < 10) {
        containerInformation[13] = null;
      }
    }
    if (containerInformation[12] !== null) {
      if (containerInformation[12].length < 10) {
        containerInformation[12] = null;
      }
    }
    // ERD, LFD, CUT DATE
    let newOBJ = {
      invoiceNumber: invoiceNumber,
      dispatchNumber: dispatchNumber,
      custcode: customerProfile[0],
      shippercode: shipperProfile[0],
      consigneecode: consigneeProfile[0],
      ContainerNumber: containerInformation[0],
      BookingNumber: containerInformation[1],
      OriginalLocation: containerInformation[2],
      Location_In: containerInformation[3],
      SSLine: containerInformation[4],
      Size: containerInformation[5],
      Status: containerInformation[6],
      chassisNumber: containerInformation[7],
      ShipperNumber: containerInformation[8],
      PinNumber: containerInformation[9],
      ReferencePoNumber: containerInformation[10],
      Weight: containerInformation[11],
      MTDue: containerInformation[12],
      ERD: containerInformation[13],
      PortAppointment: containerInformation[14],
      PortDate: containerInformation[15],
      LFD: containerInformation[16],
      CutOff: containerInformation[17],
      deliveryDate: containerInformation[18],
      Pieces: "",
      TerminalOut: "",
      isDropped: isDropped,
      OutGateDate: outGateDate,

      charges: checkNumber(costInformation[0]),
      fscPercentage: checkNumber(costInformation[1]),
      Tolls: checkNumber(costInformation[2]),
      chassisrental: checkNumber(costInformation[3]),
      SplitChassis: checkNumber(costInformation[4]),
      GateFee: checkNumber(costInformation[5]),
      Drop: checkNumber(costInformation[6]),
      Congestion: checkNumber(costInformation[7]),
      standby: checkNumber(costInformation[8]),
      Storage: checkNumber(costInformation[9]),
      Scale: checkNumber(costInformation[10]),
      Genset: checkNumber(costInformation[11]),
      PerDiem: checkNumber(costInformation[12]),
      OverSize: checkNumber(costInformation[13]),
      LoadGateFee: checkNumber(costInformation[14]),
      PrePull: checkNumber(costInformation[15]),
      StopOff: checkNumber(costInformation[16]),
      Other: checkNumber(costInformation[17]),

      Date1: formatDate(containerOutOBJ.todayDate),
      Extra1: toInt(containerOutOBJ.extra),
      Tolls1: toInt(containerOutOBJ.tolls),
      Rate1: toInt(containerOutOBJ.rate),
      check1: containerOutOBJ.cheque,
      Dest1: containerOutOBJ.location,
      Destnote1: containerOutOBJ.locationNote,
      Driver1: containerOutOBJ.driver,
      Drivernote1: containerOutOBJ.driverNote,
      In1: 0,
      Out1: 1,
      DropNumber1: 0,
      Dropfsc1: toInt(containerOutOBJ.fscpercent),

      Date2: null,
      Extra2: toInt(null),
      Tolls2: toInt(null),
      Rate2: toInt(null),
      check2: null,
      Dest2: null,
      Destnote2: null,
      Driver2: null,
      Drivernote2: null,
      In2: 0,
      Out2: 0,
      DropNumber2: 1,
      Dropfsc2: 0,

      Date3: null,
      Extra3: toInt(null),
      Tolls3: toInt(null),
      Rate3: toInt(null),
      check3: null,
      Dest3: null,
      Destnote3: null,
      Driver3: null,
      Drivernote3: null,
      In3: 0,
      Out3: 0,
      DropNumber3: 2,
      Dropfsc3: 0,

      Date4: null,
      Extra4: toInt(null),
      Tolls4: toInt(null),
      Rate4: toInt(null),
      check4: null,
      Dest4: null,
      Destnote4: null,
      Driver4: null,
      Drivernote4: null,
      In4: 0,
      Out4: 0,
      DropNumber4: 3,
      Dropfsc4: 0,

      Date5: null,
      Extra5: toInt(null),
      Tolls5: toInt(null),
      Rate5: toInt(null),
      check5: null,
      Dest5: null,
      Destnote5: null,
      Driver5: null,
      Drivernote5: null,
      In5: 0,
      Out5: 0,
      DropNumber5: 4,
      Dropfsc5: 0,

      Date6: formatDate(containerInOBJ.todayDate),
      Extra6: toInt(containerInOBJ.extra),
      Tolls6: toInt(containerInOBJ.tolls),
      Rate6: toInt(containerInOBJ.rate),
      check6: containerInOBJ.cheque,
      Dest6: containerInOBJ.location,
      Destnote6: containerInOBJ.locationNote.replace(/'/g, "''"),
      Driver6: containerInOBJ.driver,
      Drivernote6: containerInOBJ.driverNote.replace(/'/g, "''"),
      In6: 1,
      Out6: 0,
      DropNumber6: 5,
      Dropfsc6: toInt(containerInOBJ.fscpercent),

      fsc: calculatedCosts[0],
      TotalDue: calculatedCosts[1],
      costs: calculatedCosts[2],
      profit: calculatedCosts[3],
    };
    for (let x = 0; x < dropsObj.length; x++) {
      newOBJ["Date" + (x + 2)] = formatDate(dropsObj[x].todayDate);
      newOBJ["Extra" + (x + 2)] = toInt(dropsObj[x].extra);
      newOBJ["Tolls" + (x + 2)] = toInt(dropsObj[x].tolls);
      newOBJ["Rate" + (x + 2)] = toInt(dropsObj[x].rate);
      newOBJ["check" + (x + 2)] = dropsObj[x].cheque;
      newOBJ["Dest" + (x + 2)] = dropsObj[x].location;
      newOBJ["Destnote" + (x + 2)] = dropsObj[x].locationNote.replace(/'/g, "''");
      newOBJ["Driver" + (x + 2)] = dropsObj[x].driver;
      newOBJ["Drivernote" + (x + 2)] = dropsObj[x].driverNote.replace(/'/g, "''");
      newOBJ["Dropfsc" + (x + 2)] = toInt(dropsObj[x].fscpercent);
    }
    return newOBJ;
  };
  const createDatePicker = (atributeName, index) => {
    const containerDateAttributes = [
      "LFD",
      "Cut Off",
      "EmptyDue",
      "ERD",
      "PortDate",
    ];
    const containerLocationAttributes = [
      "Original Location",
      "Location In",
      "TerminalOut",
    ];
    if (atributeName === "Weight") {
      return (
        <CurrencyTextField
          label={atributeName}
          size="small"
          variant="outlined"
          value={containerInformation[index] || 0}
          currencySymbol="$"
          maximumValue="80000"
          InputLabelProps={{ shrink: true }}
          inputProps={{ readOnly: paidStatus }}
          textAlign="left"
          style={{ width: 150, marginRight: 5, marginTop: 5 }}
          onChange={(event, value) => {
            let newinfo = [...containerInformation];
            newinfo[index] = value;
            setContainerInformation(newinfo);
          }}
        ></CurrencyTextField>
      );
    } else if (containerLocationAttributes.includes(atributeName)) {
      return createLocationPicker(atributeName, index);
    } else if (containerDateAttributes.includes(atributeName)) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            variant="inline"
            inputFormat="MM/dd/yyyy"
            label={atributeName}
            size="small"
            inputProps={{ readOnly: paidStatus }}
            value={containerInformation[index] || null}
            onChange={(e) => {
              handleAttributeDateChange(index, e);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 150, marginRight: 5, marginTop: 5 }}
              />
            )}
          />
        </LocalizationProvider>
      );
    } else if (atributeName === "OutGate Date") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            variant="inline"
            inputFormat="MM/dd/yyyy"
            label={"OutGate Date"}
            value={outGateDate || null}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              handleOutGateDateChange(e);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 150, marginRight: 5, marginTop: 5 }}
              />
            )}
          />
        </LocalizationProvider>
      );
    } else if (atributeName === "SS Line") {
      return (
        <TextField
          variant="outlined"
          id={atributeName}
          label={atributeName}
          value={containerInformation[4]}
          size="small"
          style={{ width: 150, marginRight: 5, marginTop: 5 }}
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        ></TextField>
      );
    } else if (atributeName === "Availability") {
      return (
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="Availability" style={{ marginTop: 5 }}>
            Availability
          </InputLabel>
          <Select
            value={containerInformation[6] || "READY"}
            label="Availability"
            labelId="Availability"
            style={{ width: 150, marginRight: 5, marginTop: 5 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newinfo = [...containerInformation];
              newinfo[index] = e.target.value.trim();
              setContainerInformation(newinfo);
            }}
          >
            <MenuItem value={"READY"}>READY</MenuItem>
            <MenuItem value={"NOT READY"}>NOT READY</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (atributeName === "Container Number") {
      return (
        <TextField
          variant="outlined"
          id={atributeName}
          label={"Container Number"}
          name={"Container Number"}
          value={containerInformation[0]}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            readOnly: paidStatus,
            maxLength: 11,
            style: { textTransform: "uppercase" },
          }}
          style={{ width: 150, marginRight: 5, marginTop: 5 }}
          size="small"
          autoComplete="off"
          onChange={(e) => {
            let newinfo = [...containerInformation];
            if (e.target.value === "") {
              newinfo[0] = null;
            } else {
              newinfo[0] = e.target.value;
            }
            setContainerInformation(newinfo);
          }}
        ></TextField>
      );
    } else if (atributeName === "Delivery Date") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Delivery Date"
            value={containerInformation[18] || null}
            ampm={true}
            format="yyyy/MM/dd hh:mm a"
            inputProps={{
              readOnly: paidStatus,
              style: { textTransform: "uppercase" },
            }}
            onChange={(e) => {
              let newinfo = [...containerInformation];
              newinfo[18] = e;
              setContainerInformation(newinfo);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 220, marginRight: 5, marginTop: 5 }}
              />
            )}
          />
        </LocalizationProvider>
      );
    } else {
      return (
        <TextField
          variant="outlined"
          id={atributeName}
          label={atributeName}
          name={atributeName}
          value={containerInformation[index]}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            readOnly: paidStatus,
            maxLength: 30,
            style: { textTransform: "uppercase" },
          }}
          size="small"
          style={{ width: 150, marginRight: 5, marginTop: 5 }}
          autoComplete="off"
          onChange={(e) => {
            let newinfo = [...containerInformation];
            if (e.target.value === "") {
              newinfo[index] = null;
            } else {
              newinfo[index] = e.target.value;
            }
            setContainerInformation(newinfo);
          }}
        ></TextField>
      );
    }
  };
  const createLocationPicker = (atributeName, index) => {
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel
          id={atributeName}
          InputLabelProps={{ shrink: true }}
          style={{ marginTop: 5 }}
        >
          {atributeName}
        </InputLabel>
        <Select
          value={containerInformation[index]}
          label={atributeName}
          labelId={atributeName}
          style={{ width: 150, marginRight: 5, marginTop: 5 }}
          inputProps={{ readOnly: paidStatus }}
          onChange={(e) => {
            let newinfo = [...containerInformation];
            if (e.target.value === "") {
              newinfo[index] = null;
            } else {
              newinfo[index] = e.target.value;
            }
            setContainerInformation(newinfo);
          }}
        >
          <MenuItem>{atributeName}</MenuItem>
          {ports.map((mapValue) => {
            return <MenuItem value={mapValue}>{mapValue}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  };
  const lookupCustomer = (code) => {
    for (let x = 0; x < billingAttributes.length; x++) {
      if (code === billingAttributes[x].code) {
        let address = `${billingAttributes[x].street}, ${billingAttributes[x].city}, ${billingAttributes[x].state}, ${billingAttributes[x].zip}`;
        setCustomerProfile([
          billingAttributes[x].code,
          billingAttributes[x].name,
          address,
        ]);
      }
    }
  };
  const lookupConsignee = (code) => {
    for (let x = 0; x < billingAttributes.length; x++) {
      if (code === billingAttributes[x].code) {
        let address = `${billingAttributes[x].street}, ${billingAttributes[x].city}, ${billingAttributes[x].state}, ${billingAttributes[x].zip}`;
        setConsigneeProfile([
          billingAttributes[x].code,
          billingAttributes[x].name,
          address,
        ]);
      }
    }
  };
  const lookupShipper = (code) => {
    for (let x = 0; x < shippers.length; x++) {
      if (code === shippers[x].code) {
        let address = `${shippers[x].street}, ${shippers[x].city}, ${shippers[x].state}, ${shippers[x].zip}`;
        setShipperProfile([shippers[x].code, shippers[x].name, address]);
      }
    }
  };
  const createSelector = (codeType, codeValue, mapArray) => {
    if (codeType === "customer") {
      return (
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="CustCode"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 5 }}
          >
            CustCode
          </InputLabel>
          <Select
            label="CustCode"
            labelId="CustCode"
            value={codeValue || "CustCode"}
            style={{ width: 150, marginTop: 5 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newCustomerInfo = [...customerProfile];
              newCustomerInfo[0] = e.target.value;
              setCustomerProfile(newCustomerInfo);
              lookupCustomer(e.target.value);
            }}
          >
            <MenuItem>CustCode</MenuItem>
            {mapArray.map((mapValue) => {
              return <MenuItem value={mapValue.code}>{mapValue.code}</MenuItem>;
            })}
          </Select>
        </FormControl>
      );
    } else if (codeType === "consignee") {
      return (
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="ConsigneeCode"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 5 }}
          >
            ConsigneeCode
          </InputLabel>
          <Select
            label="ConsigneeCode"
            labelId="ConsigneeCode"
            value={codeValue || "ConsigneeCode"}
            style={{ width: 150, marginTop: 5 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newConsigneeInfo = [...consigneeProfile];
              newConsigneeInfo[0] = e.target.value;
              setConsigneeProfile(newConsigneeInfo);
              lookupConsignee(e.target.value);
            }}
          >
            <MenuItem>ConsigneeCode</MenuItem>
            {mapArray.map((mapValue) => {
              return <MenuItem value={mapValue.code}>{mapValue.code}</MenuItem>;
            })}
          </Select>
        </FormControl>
      );
    } else if (codeType === "shipper") {
      return (
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="ShipperCode"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 5 }}
          >
            ShipperCode
          </InputLabel>
          <Select
            label="ShipperCode"
            labelId="ShipperCode"
            value={codeValue || "ShipperCode"}
            style={{ width: 150, marginTop: 5 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newShipperInfo = [...shipperProfile];
              newShipperInfo[0] = e.target.value;
              setShipperProfile(newShipperInfo);
              lookupShipper(e.target.value);
              let newinfo = [...containerInformation];
              newinfo[4] = e.target.value;
              setContainerInformation(newinfo);
            }}
          >
            <MenuItem>ShipperCode</MenuItem>
            {shippers.map((mapValue) => {
              return (
                <MenuItem key={mapValue.code} value={mapValue.code}>
                  {mapValue.code}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  };
  const isPaid = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          variant="inline"
          inputFormat="MM/dd/yyyy"
          label="Billed Date"
          value={billedDate || null}
          inputProps={{ readOnly: paidStatus }}
          onChange={(e) => handlebilledDateChange(e)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              style={{ width: 150, marginTop: 5 }}
            />
          )}
        />
      </LocalizationProvider>
    );
  };
  const createDrops = () => {
    if (dropsObj.length < 1) {
      return (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={addDrop}
          margin="normal"
          style={{ fontSize: 10 }}
        >
          Add Drop
        </Button>
      );
    } else {
      return dropsObj.map((drop, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            sm={12}
            justify="center"
          >
            <Typography variant="h6" style={{ width: 60 }}>
              {index + 1}:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                variant="inline"
                inputFormat="MM/dd/yyyy"
                label="Date"
                size="small"
                inputProps={{ readOnly: paidStatus }}
                value={formatOutGateDate(dropsObj[index].todayDate) || null}
                onChange={(e) => handleDateChange(index, e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControl variant="outlined" size="small">
              <InputLabel
                id="Location"
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: 10 }}
              >
                Location
              </InputLabel>
              <Select
                label="Location"
                labelId="Location"
                value={
                  String(dropsObj[index].location).trim().toUpperCase() || "Location"
                }
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 150, marginRight: 5, marginTop: 10 }}
                onChange={(e) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].location = e.target.value;
                  setDropsObj(newNewDrops);
                }}
              >
                <MenuItem value="">Location</MenuItem>
                {locations.map((location) => {
                  return (
                    <MenuItem value={String(location).trim().toUpperCase()}>{location.trim()}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
              <InputLabel
                id="Driver"
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: 10 }}
              >
                Driver
              </InputLabel>
              <Select
                label="Driver"
                labelId="Driver"
                value={String(dropsObj[index].driver).toUpperCase() || "Driver"}
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 150, marginRight: 5, marginTop: 10 }}
                onChange={(e) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].driver = e.target.value;
                  setDropsObj(newNewDrops);
                }}
              >
                <MenuItem value="">Driver</MenuItem>
                {drivers.map((driver) => {
                  return (
                    <MenuItem value={String(driver).toUpperCase()}>
                      {driver}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <CurrencyTextField
                label="Rate"
                size="small"
                variant="outlined"
                value={dropsObj[index].rate || "0"}
                currencySymbol="$"
                maximumValue="10000"
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 100, marginRight: 5, marginTop: 10 }}
                textAlign="left"
                onChange={(event, value) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].rate = value;
                  setDropsObj(newNewDrops);
                }}
              ></CurrencyTextField>
            </FormControl>
            <FormControl>
              <CurrencyTextField
                label="Tolls"
                variant="outlined"
                size="small"
                value={dropsObj[index].tolls || "0"}
                currencySymbol="$"
                maximumValue="10000"
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 100, marginRight: 5, marginTop: 10 }}
                textAlign="left"
                onChange={(event, value) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].tolls = value;
                  setDropsObj(newNewDrops);
                }}
              ></CurrencyTextField>
            </FormControl>
            <FormControl>
              <CurrencyTextField
                label="Extra"
                variant="outlined"
                size="small"
                value={dropsObj[index].extra || "0"}
                currencySymbol="$"
                maximumValue="10000"
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 100, marginRight: 5, marginTop: 10 }}
                textAlign="left"
                onChange={(event, value) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].extra = value;
                  setDropsObj(newNewDrops);
                }}
              ></CurrencyTextField>
            </FormControl>
            <FormControl>
              <CurrencyTextField
                label={"FSC%"}
                size="small"
                variant="outlined"
                value={dropsObj[index].fscpercent || 0}
                currencySymbol="%"
                maximumValue="100"
                inputProps={{ readOnly: paidStatus }}
                style={{ width: 100, marginRight: 5, marginTop: 10 }}
                textAlign="left"
                onChange={(event, value) => {
                  let newNewDrops = [...dropsObj];
                  newNewDrops[index].fscpercent = value;
                  setDropsObj(newNewDrops);
                }}
              ></CurrencyTextField>
            </FormControl>
            <TextField
              id="Cheque"
              label="Cheque"
              name="Cheque"
              size="small"
              value={dropsObj[index].cheque}
              style={{ marginRight: 5, marginTop: 10, width: 100 }}
              autoComplete="off"
              inputProps={{ readOnly: true }}
            ></TextField>
            <TextField
              variant="outlined"
              id="destination_note"
              label="Destination Note"
              name="destination_note"
              value={dropsObj[index].locationNote}
              size="small"
              style={{ marginRight: 5, marginTop: 10 }}
              inputProps={{ readOnly: paidStatus }}
              autoComplete="off"
              onChange={(e) => {
                let newNewDrops = [...dropsObj];
                newNewDrops[index].locationNote = e.target.value;
                setDropsObj(newNewDrops);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              id="driver_note"
              label="Driver Note"
              name="driver_note"
              value={dropsObj[index].driverNote}
              size="small"
              style={{ marginRight: 5, marginTop: 10 }}
              inputProps={{ readOnly: paidStatus }}
              autoComplete="off"
              onChange={(e) => {
                let newNewDrops = [...dropsObj];
                newNewDrops[index].driverNote = e.target.value;
                setDropsObj(newNewDrops);
              }}
            ></TextField>
            {lastMap(index)}
            {firstMap(index)}
          </Grid>
        );
      });
    }
  };
  const createContainerIn = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        sm={12}
        justify="center"
      >
        <Typography variant="h6" style={{ width: 60 }}>
          In:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            variant="inline"
            inputFormat="MM/dd/yyyy"
            label="Date"
            inputProps={{ readOnly: paidStatus }}
            value={formatOutGateDate(containerInOBJ.todayDate) || null}
            onChange={handleOutDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 150, marginRight: 5, marginTop: 10 }}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="Location"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 10 }}
          >
            Location
          </InputLabel>
          <Select
            label="Location"
            labelId="Location"
            value={String(containerInOBJ.location).trim().toUpperCase() || "Location"}
            style={{ width: 150, marginRight: 5, marginTop: 10 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newContainerOut = containerInOBJ;
              newContainerOut.location = e.target.value;
              setContainerInOBJ(newContainerOut);
              calculateFSC()
            }}
          >
            <MenuItem value="">Location</MenuItem>

            {locations.map((location) => {
              return <MenuItem value={String(location).trim().toUpperCase()}>{location.trim()}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
        >
          <InputLabel
            id="Driver"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 10 }}
          >
            Driver
          </InputLabel>
          <Select
            label="Driver"
            labelId="Driver"
            value={String(containerInOBJ.driver).toUpperCase() || "Driver"}
            style={{ width: 150, marginRight: 5, marginTop: 10 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newContainerIn = containerInOBJ;
              newContainerIn.driver = e.target.value;
              setContainerInOBJ(newContainerIn);
              calculateFSC()
            }}
          >
            <MenuItem value="">Driver</MenuItem>
            {drivers.map((driver) => {
              return <MenuItem value={driver.trim()}>{driver.trim()}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Rate"
            size="small"
            variant="outlined"
            value={containerInOBJ.rate || "0"}
            currencySymbol="$"
            maximumValue="10000"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerOut = containerInOBJ;
              newContainerOut.rate = value;
              setContainerInOBJ(newContainerOut);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Tolls"
            size="small"
            variant="outlined"
            value={containerInOBJ.tolls || "0"}
            currencySymbol="$"
            maximumValue="10000"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerOut = containerInOBJ;
              newContainerOut.tolls = value;
              setContainerInOBJ(newContainerOut);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Extra"
            variant="outlined"
            value={containerInOBJ.extra || "0"}
            currencySymbol="$"
            maximumValue="10000"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            size="small"
            textAlign="left"
            onChange={(event, value) => {
              let newContainerOut = containerInOBJ;
              newContainerOut.extra = value;
              setContainerInOBJ(newContainerOut);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label={"FSC%"}
            size="small"
            variant="outlined"
            value={containerInOBJ.fscpercent || 0}
            currencySymbol="%"
            maximumValue="100"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerOut = containerInOBJ;
              newContainerOut.fscpercent = value;
              setContainerInOBJ(newContainerOut);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <TextField
          id="Cheque"
          label="Cheque"
          name="Cheque"
          value={containerInOBJ.cheque}
          style={{ marginRight: 5, marginTop: 10, width: 100 }}
          inputProps={{ maxLength: 20, readOnly: true }}
          size="small"
          autoComplete="off"
        ></TextField>
        <TextField
          variant="outlined"
          id="destination_note"
          label="Destination Note"
          name="destination_note"
          inputProps={{ readOnly: paidStatus }}
          value={containerInOBJ.locationNote}
          style={{ marginRight: 5, marginTop: 10 }}
          size="small"
          autoComplete="off"
          onChange={(e) => {
            let newContainerOut = containerInOBJ;
            newContainerOut.locationNote = e.target.value;
            setContainerInOBJ(newContainerOut);
            calculateFSC()
          }}
        ></TextField>
        <TextField
          variant="outlined"
          id="driver_note"
          label="Driver Note"
          name="driver_note"
          inputProps={{ readOnly: paidStatus }}
          value={containerInOBJ.driverNote}
          style={{ marginRight: 5, marginTop: 10 }}
          size="small"
          autoComplete="off"
          onChange={(e) => {
            let newContainerOut = containerInOBJ;
            newContainerOut.driverNote = e.target.value;
            setContainerInOBJ(newContainerOut);
            calculateFSC()
          }}
        ></TextField>
      </Grid>
    );
  };
  const createContainerOut = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        sm={12}
      >
        <Typography variant="h6" style={{ width: 60 }}>
          Out:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            variant="inline"
            inputFormat="MM/dd/yyyy"
            label="Date"
            inputProps={{ readOnly: paidStatus }}
            value={formatOutGateDate(containerOutOBJ.todayDate) || null}
            onChange={handleInDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 150, marginRight: 5, marginTop: 10 }}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="Location"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 10 }}
          >
            Location
          </InputLabel>
          <Select
            label="Location"
            labelId="Location"
            value={String(containerOutOBJ.location).trim().toUpperCase() || "Location"}
            inputProps={{ readOnly: paidStatus }}
            style={{ width: 150, marginRight: 5, marginTop: 10 }}
            onChange={(e) => {
              let newContainerOut = containerOutOBJ;
              newContainerOut.location = e.target.value;
              setContainerOutOBJ(newContainerOut);
              calculateFSC()
            }}
          >
            <MenuItem value="">Location</MenuItem>
            {locations.map((location) => {
              return (
                <MenuItem value={String(location).trim().toUpperCase()}>{location.trim()}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel
            id="Driver"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 10 }}
          >
            Driver
          </InputLabel>
          <Select
            label="Driver"
            labelId="Driver"
            value={String(containerOutOBJ.driver).toUpperCase() || "Driver"}
            style={{ width: 150, marginRight: 5, marginTop: 10 }}
            inputProps={{ readOnly: paidStatus }}
            onChange={(e) => {
              let newContainerIn = containerOutOBJ;
              newContainerIn.driver = e.target.value;
              setContainerOutOBJ(newContainerIn);
              calculateFSC()
            }}
          >
            <MenuItem value="">Driver</MenuItem>
            {drivers.map((driver) => {
              return <MenuItem value={driver.trim()}>{driver.trim()}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Rate"
            size="small"
            variant="outlined"
            maximumValue="10000"
            inputProps={{ readOnly: paidStatus }}
            value={containerOutOBJ.rate || "0"}
            currencySymbol="$"
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerIn = containerOutOBJ;
              newContainerIn.rate = value;
              setContainerOutOBJ(newContainerIn);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Tolls"
            size="small"
            variant="outlined"
            maximumValue="10000"
            inputProps={{ readOnly: paidStatus }}
            value={containerOutOBJ.tolls || "0"}
            currencySymbol="$"
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerIn = containerOutOBJ;
              newContainerIn.tolls = value;
              setContainerOutOBJ(newContainerIn);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label="Extra"
            variant="outlined"
            value={containerOutOBJ.extra || "0"}
            currencySymbol="$"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            size="small"
            textAlign="left"
            maximumValue="10000"
            onChange={(event, value) => {
              let newContainerIn = containerOutOBJ;
              newContainerIn.extra = value;
              setContainerOutOBJ(newContainerIn);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <FormControl>
          <CurrencyTextField
            label={"FSC%"}
            size="small"
            variant="outlined"
            value={containerOutOBJ.fscpercent || 0}
            currencySymbol="%"
            maximumValue="100"
            inputProps={{ readOnly: paidStatus }}
            style={{ marginRight: 5, width: 100, marginTop: 10 }}
            textAlign="left"
            onChange={(event, value) => {
              let newContainerIn = containerOutOBJ;
              newContainerIn.fscpercent = value;
              setContainerOutOBJ(newContainerIn);
              calculateFSC()
            }}
          ></CurrencyTextField>
        </FormControl>
        <TextField
          id="Cheque"
          label="Cheque"
          name="Cheque"
          value={containerOutOBJ.cheque}
          inputProps={{ maxLength: 10, readOnly: true }}
          style={{ marginRight: 5, marginTop: 10, width: 100 }}
          size="small"
        ></TextField>
        <TextField
          variant="outlined"
          id="destination_note"
          label="Destination Note"
          name="destination_note"
          inputProps={{ readOnly: paidStatus }}
          value={containerOutOBJ.locationNote}
          style={{ marginRight: 5, marginTop: 10 }}
          autoComplete="off"
          size="small"
          onChange={(e) => {
            let newContainerIn = containerOutOBJ;
            newContainerIn.locationNote = e.target.value;
            setContainerOutOBJ(newContainerIn);
            calculateFSC()
          }}
        ></TextField>
        <TextField
          variant="outlined"
          id="driver_note"
          label="Driver Note"
          name="driver_note"
          inputProps={{ readOnly: paidStatus }}
          value={containerOutOBJ.driverNote}
          style={{ marginRight: 5, marginTop: 10 }}
          size="small"
          autoComplete="off"
          onChange={(e) => {
            let newContainerIn = containerOutOBJ;
            newContainerIn.driverNote = e.target.value;
            setContainerOutOBJ(newContainerIn);
            calculateFSC()
          }}
        ></TextField>
      </Grid>
    );
  };
  const handleKeyPressInvoice = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressContainer = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressBooking = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressCust = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressCons = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressPo = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressChassis = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const handleKeyPressDispatch = (target) => {
    if (target.charCode === 13) {
      document.getElementById("searchAll").click();
    }
  };
  const historyButtons = () => {
    return (
      <Grid item xs={12} sm={12}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="note"
          size="small"
          label="Note"
          name="note"
          inputProps={{ readOnly: paidStatus }}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        ></TextField>
        <Button
          width="50%"
          variant="contained"
          color="primary"
          onClick={addNote}
        >
          Add note
        </Button>
      </Grid>
    );
  };
  const calculateFSC = () => {
    let dropsCost = 0;
    dropsCost =
    parseFloat(toInt(containerOutOBJ.rate)) +
    parseFloat(toInt(containerOutOBJ.tolls)) +
    parseFloat(toInt(containerOutOBJ.extra)) +
    parseFloat(toInt(containerOutOBJ.rate)) * (parseFloat(toInt(containerOutOBJ.fscpercent)) / 100) +
    parseFloat(toInt(containerInOBJ.rate)) +
    parseFloat(toInt(containerInOBJ.tolls)) +
    parseFloat(toInt(containerInOBJ.extra)) +
    parseFloat(toInt(containerInOBJ.rate)) * (parseFloat(toInt(containerInOBJ.fscpercent)) / 100);
    for (let x = 0; x < dropsObj.length; x++) {
      dropsCost +=
      parseFloat(toInt(dropsObj[x].rate)) +
      parseFloat(toInt(dropsObj[x].tolls)) +
      parseFloat(toInt(dropsObj[x].extra)) +
      parseFloat(toInt(dropsObj[x].rate)) * (parseFloat(toInt(dropsObj[x].fscpercent)) / 100);
    }
    setCostsTotal(dropsCost);
    let costsTotalDue =
    parseFloat(toInt(costInformation[0])) +
    parseFloat(toInt(costInformation[2])) +
    parseFloat(toInt(costInformation[3])) +
    parseFloat(toInt(costInformation[4])) +
    parseFloat(toInt(costInformation[5])) +
    parseFloat(toInt(costInformation[6])) +
    parseFloat(toInt(costInformation[7])) +
    parseFloat(toInt(costInformation[8])) +
    parseFloat(toInt(costInformation[9])) +
    parseFloat(toInt(costInformation[10])) +
    parseFloat(toInt(costInformation[11])) +
    parseFloat(toInt(costInformation[12])) +
    parseFloat(toInt(costInformation[13])) +
    parseFloat(toInt(costInformation[14])) +
    parseFloat(toInt(costInformation[15])) +
    parseFloat(toInt(costInformation[16])) +
    parseFloat(toInt(costInformation[17]));

    let profit =
    parseFloat(toInt(costInformation[0])) +
    (parseFloat(toInt(costInformation[0])) * parseFloat((toInt(costInformation[1])) / 100)) +
    parseFloat(toInt(costInformation[2])) +
    parseFloat(toInt(costInformation[4])) +
    parseFloat(toInt(costInformation[6])) +
    parseFloat(toInt(costInformation[7])) +
    parseFloat(toInt(costInformation[8])) +
    parseFloat(toInt(costInformation[10])) +
    parseFloat(toInt(costInformation[11])) +
    parseFloat(toInt(costInformation[12])) +
    parseFloat(toInt(costInformation[13])) +
    parseFloat(toInt(costInformation[15])) +
    parseFloat(toInt(costInformation[16])) +
    parseFloat(toInt(costInformation[17]));
    let FSC = parseFloat(toInt(costInformation[0])) * parseFloat((toInt(costInformation[1])) / 100);
    let newCalculatedCosts = [...calculatedCosts];
    newCalculatedCosts[0] = parseFloat(toInt(FSC));
    newCalculatedCosts[1] = parseFloat(toInt(newCalculatedCosts[0])) + parseFloat(toInt(costsTotalDue));
    newCalculatedCosts[2] = dropsCost;
    newCalculatedCosts[3] = parseFloat((profit - newCalculatedCosts[2]).toFixed(2));
    setCalculatedCosts(newCalculatedCosts);
  };
  const dispatchChargesFields = (atributeName, index) => {
    if (atributeName === "FSC%") {
      return (
        <CurrencyTextField
          label={atributeName}
          size="small"
          variant="outlined"
          value={costInformation[index] || 0}
          currencySymbol="%"
          maximumValue="100"
          style={{ width: 100, marginTop: 5 }}
          inputProps={{ readOnly: paidStatus }}
          textAlign="left"
          onChange={(event, value) => {
            let newcost = [...costInformation];
            newcost[index] = value;
            setCostInformation(newcost);
          }}
        ></CurrencyTextField>
      );
    } else {
      return (
        <CurrencyTextField
          label={atributeName}
          size="small"
          variant="outlined"
          value={costInformation[index] || 0}
          currencySymbol="$"
          maximumValue="10000"
          style={{ width: 100, marginTop: 5 }}
          inputProps={{ readOnly: paidStatus }}
          textAlign="left"
          onChange={(event, value) => {
            let newcost = [...costInformation];
            newcost[index] = value;
            setCostInformation(newcost);
          }}
        ></CurrencyTextField>
      );
    }
  };
  const editNote = (oldRow, newRow, dispatchNumber) => {
    try {
      console.dir(oldRow)
      console.dir(newRow)
      console.dir(dispatchNumber)
      let oldNote = oldRow.notes
      axios
        .post(`${BASE_URL}/dispatch/editNote`, {
          dispatchNumber: dispatchNumber,
          oldrow: oldNote,
          newrow: newRow.notes,
          uid: newRow.uid
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  switch (perms) {
    case true:
      return (
        <Grid container>
          <div idleTimer={idleTimer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={12}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermInvoice"
                  label="Search By Invoice"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressInvoice}
                  onChange={(e) => {
                    setSearchInvoiceTerm(e.target.value);
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    setSearchBookingTerm("");
                    setSearchCustTerm("");
                    setSearchPoTerm("");
                    setSearchConsTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermCons").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermDispatch"
                  label="Search By Dispatch"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressDispatch}
                  onChange={(e) => {
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm(e.target.value);
                    setSearchContainerTerm("");
                    setSearchBookingTerm("");
                    setSearchCustTerm("");
                    setSearchPoTerm("");
                    setSearchConsTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermCons").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermContainer"
                  label="Search By Container"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressContainer}
                  onChange={(e) => {
                    setSearchContainerTerm(e.target.value);
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchBookingTerm("");
                    setSearchCustTerm("");
                    setSearchPoTerm("");
                    setSearchConsTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermCons").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermBooking").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermBooking"
                  label="Search By Booking"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressBooking}
                  onChange={(e) => {
                    setSearchBookingTerm(e.target.value);
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    setSearchCustTerm("");
                    setSearchPoTerm("");
                    setSearchConsTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermCons").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermCust"
                  label="Search By Cust"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressCust}
                  onChange={(e) => {
                    setSearchCustTerm(e.target.value);
                    setSearchBookingTerm("");
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    setSearchPoTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermCons"
                  label="Search By Cons"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressCons}
                  onChange={(e) => {
                    setSearchConsTerm(e.target.value);
                    setSearchBookingTerm("");
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    setSearchPoTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermPo"
                  label="Search By Po"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressPo}
                  onChange={(e) => {
                    setSearchPoTerm(e.target.value);
                    setSearchCustTerm("");
                    setSearchBookingTerm("");
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    setSearchChassisTerm("");
                    document.getElementById("searchTermChassis").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="searchTermChassis"
                  label="Search By Chassis"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  autoComplete="off"
                  onKeyPress={handleKeyPressChassis}
                  onChange={(e) => {
                    setSearchChassisTerm(e.target.value);
                    setSearchPoTerm("");
                    setSearchCustTerm("");
                    setSearchBookingTerm("");
                    setSearchInvoiceTerm("");
                    setSearchDispatchTerm("");
                    setSearchContainerTerm("");
                    document.getElementById("searchTermPo").value = "";
                    document.getElementById("searchTermInvoice").value = "";
                    document.getElementById("searchTermDispatch").value = "";
                    document.getElementById("searchTermContainer").value = "";
                    document.getElementById("searchTermBooking").value = "";
                    document.getElementById("searchTermCust").value = "";
                  }}
                ></TextField>
                <Button
                  fullWidth
                  id="searchAll"
                  variant="contained"
                  color="primary"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  onClick={searchAll}
                >
                  Search
                </Button>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="Dispatch Number"
                  label="Dispatch Number"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  value={dispatchNumber}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="Invoice Number"
                  label="Invoice Number"
                  name="search"
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  size="small"
                  value={invoiceNumber}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <Button
                  fullWidth
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={function () {
                    update();
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  fullWidth
                  style={{ width: 150, marginRight: 5, marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={function () {
                    window.location.reload();
                  }}
                >
                  New Dispatch
                </Button>
              </Grid>
            </Grid>
            {/* Customer/Consignee/Shipper/Container Information */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Customer/Shipper/Consignee:</Typography>
                  </AccordionSummary>
                  <Grid container>
                    {/* customer */}
                    <Grid item xs={12} sm={12 / 5}>
                      {createSelector(
                        "customer",
                        customerProfile[0],
                        billingAttributes
                      )}
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        value={customerProfile[1] || ""}
                        id="customer_name"
                        label="Customer Name"
                        name="customer_name"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        onChange={(e) => {
                          let newCustomerInfo = [...customerProfile];
                          newCustomerInfo[1] = e.target.value;
                          setCustomerProfile(newCustomerInfo);
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        id="customer_address"
                        label="Customer Address"
                        name="customer_address"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        value={customerProfile[2] || ""}
                        onChange={(e) => {
                          let newCustomerInfo = [...customerProfile];
                          newCustomerInfo[2] = e.target.value;
                          setCustomerProfile(newCustomerInfo);
                        }}
                      ></TextField>
                    </Grid>
                    {/* shipper */}
                    <Grid item xs={12} sm={12 / 5}>
                      {createSelector("shipper", shipperProfile[0], shippers)}
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        id="shipper_name"
                        label="Shipper Name"
                        name="shipper_name"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        value={shipperProfile[1] || ""}
                        onChange={(e) => {
                          let newShipperInfo = [...shipperProfile];
                          newShipperInfo[1] = e.target.value;
                          setShipperProfile(newShipperInfo);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        id="shipping_address"
                        label="Shipping Address"
                        name="shipping_address"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        value={shipperProfile[2] || ""}
                        onChange={(e) => {
                          let newShipperInfo = [...shipperProfile];
                          newShipperInfo[2] = e.target.value;
                          setShipperProfile(newShipperInfo);
                        }}
                      />
                    </Grid>
                    {/* consignee */}
                    <Grid item xs={12} sm={12 / 5}>
                      {createSelector(
                        "consignee",
                        consigneeProfile[0],
                        billingAttributes
                      )}
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        id="billing_name"
                        label="Consignee Name"
                        name="billing_name"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        value={consigneeProfile[1] || ""}
                        onChange={(e) => {
                          let newConsigneeInfo = [...consigneeProfile];
                          newConsigneeInfo[1] = e.target.value;
                          setConsigneeProfile(newConsigneeInfo);
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={(12 - 12 / 5) / 2}>
                      <TextField
                        variant="outlined"
                        id="billing_address"
                        label="Consignee Address"
                        name="billing_address"
                        size="small"
                        style={{ width: 300, marginRight: 5, marginTop: 5 }}
                        inputProps={{ readOnly: true }}
                        value={consigneeProfile[2] || ""}
                        onChange={(e) => {
                          let newConsigneeInfo = [...consigneeProfile];
                          newConsigneeInfo[2] = e.target.value;
                          setConsigneeProfile(newConsigneeInfo);
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Accordion>
              </Grid>
              {/* Container Information */}
              <Grid item xs={12} sm={7}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    style={{ height: 20 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Container Information</Typography>
                  </AccordionSummary>
                  <Grid container>
                    {containerAttributes.map((atributeName, index) => (
                      <Grid item xs={12} sm={12 / 7}>
                        {createDatePicker(atributeName, index)}
                      </Grid>
                    ))}
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
            {/* Driver Assign/Costs */}
            <Grid container spacing={1}>
              {/* Container Out/Drops/In*/}
              <Grid item xs={12} sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    style={{ height: 20 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Container In/Out</Typography>
                  </AccordionSummary>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ border: "1px solid black" }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                      sm={12}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isDropped}
                            onChange={(e) => {
                              setIsDropped(e.target.checked);
                            }}
                          />
                        }
                        label="Dropped"
                      />
                    </Grid>
                    {/* Container Out */}
                    {createContainerOut()}
                    {/* Drops */}
                    {createDrops()}
                    {/* Container In */}
                    {createContainerIn()}
                  </Grid>
                </Accordion>
              </Grid>
              {/* History */}
              <Grid item xs={12} sm={5}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    style={{ height: 20 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography style={{ marginLeft: 10 }}>
                      Dispatch Note
                    </Typography>
                  </AccordionSummary>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <MaterialTable
                        icons={tableIcons}
                        title="History"
                        data={notes}
                        columns={[
                          {
                            field: "notes",
                            title: "Dispatch Note",
                          },
                        ]}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "#DEF3FA",
                            color: "Black",
                            borderRight: "1px solid ",
                          },
                        }}
                        editable={{
                          onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                              editNote(oldRow, newRow, dispatchNumber);
                              const newnotes = [...notes];
                              newnotes[oldRow.tableData.id] = newRow;
                              setnotes(newnotes);
                              setTimeout(() => resolve(), 1000);
                            }),
                        }}
                      />
                    </Grid>
                    {historyButtons()}
                  </Grid>
                </Accordion>
              </Grid>
              {/* Costs */}
              <Grid item xs={12} sm={7}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    style={{ height: 20 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Charges</Typography>
                  </AccordionSummary>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {costAttributes.map((atributeName, index) => (
                      <Grid item xs={12} sm={12/11}>
                        {dispatchChargesFields(atributeName, index)}
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={12 / 7}>
                      {isPaid()}
                    </Grid>
                    <Grid item xs={12} sm={12 / 7} alignItems="center">
                      <FormControlLabel
                        control={<Checkbox id="paidStatus" />}
                        label="Paid"
                        checked={paidStatus}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {calculatedAttributes.map((atributeName, index) => (
                      <Grid item xs={12} sm={12 / 8}>
                        <TextField
                          variant="outlined"
                          style={{ marginTop: 5, width: 150 }}
                          fullWidth
                          value={calculatedCosts[index] || 0.0}
                          id={atributeName}
                          size="small"
                          label={atributeName}
                          name={atributeName}
                          disabled
                        ></TextField>
                      </Grid>
                    ))}
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
            {/* Container */}
            <Dialog
              fullWidth
              maxWidth="false"
              open={modal2}
              onClose={toggleModal2}
            >
              <DialogContent className="dialogbg">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <h1>Pick A Container</h1>
                    <Box>
                      <MaterialTable
                        icons={tableIcons}
                        title="Duplicates"
                        data={duplicateContainers}
                        columns={[
                          {
                            field: "dispatchNumber",
                            title: "dispatchNumber",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "invoiceNumber",
                            title: "invoiceNumber",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "containerNumber",
                            title: "containerNumber",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "customer",
                            title: "customer",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "consignee",
                            title: "consignee",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "booking",
                            title: "booking",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                          {
                            field: "chassis",
                            title: "chassis",
                            width: null,
                            cellStyle: { width: 10 },
                          },
                        ]}
                        options={{
                          paging: false,
                          cellStyle: { borderRight: "1px solid " },
                          headerStyle: {
                            backgroundColor: "#DEF3FA",
                            color: "Black",
                            borderRight: "1px solid ",
                          },
                          maxBodyHeight: 350,
                        }}
                        onRowClick={(evt, selectedRow) => {
                          searchDispatchFromBoard(
                            duplicateContainers[selectedRow.tableData.id]
                              .dispatchNumber
                          );
                          document.getElementById("searchTermContainer").value =
                            "";
                          toggleModal2();
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
        </Grid>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}