import React, { useState, useEffect, Fragment, forwardRef } from "react";
import axios from "axios";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import { TablePagination } from "@mui/material";
import {
  CssBaseline,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  createMuiTheme,
  ThemeProvider,
  TextField,
  Button,
} from "@mui/material";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BASE_URL, fullCompanyName } from "./config"; 

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function Admin() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [grandTotalDue, setGrandTotalDue] = useState(0);
  const [totalCosts, setTotalCosts] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState("unbilled");
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalsList, setTotalsList] = useState({
    all: [0, 0, 0],
    bap: [0, 0, 0],
    bnp: [0, 0, 0],
    billed: [0, 0, 0],
    unbilled: [0, 0, 0],
  });
  const itemsPerPage = 500;
  const totalPages = Math.ceil((invoiceList[selectedValue] || []).length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const buttonStyle = {
    backgroundColor: "#1976d2", // Change this to the desired background color
    color: "#fff", // Change this to the desired text color
    padding: "8px 16px", // Adjust the padding as needed
    fontWeight: "bold",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px", // Add some spacing between buttons
    marginTop: '10px'
  };
  const formattedTotalProfit = totalProfit ? (Math.round(totalProfit * 100) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }) : '';
  const formattedTotalCosts = totalCosts ? (Math.round(totalCosts * 100) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }) : '';
  const formattedGrandTotalDue = grandTotalDue ? (Math.round(grandTotalDue * 100) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }) : '';
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [filterQuery, setFilterQuery] = useState('');
  const applyFilter = (data) => {
    if (!filterQuery) {
      return data;
    }
    return data.filter(rowData =>
      rowData.customer.toUpperCase().includes(filterQuery.toUpperCase()) ||
      rowData.consignee.toUpperCase().includes(filterQuery.toUpperCase()) ||
      rowData.shipper.toUpperCase().includes(filterQuery.toUpperCase()) ||
      rowData.invoiceNumber.toUpperCase().includes(filterQuery.toUpperCase()) ||
      rowData.container.toUpperCase().includes(filterQuery.toUpperCase()) ||
      rowData.bookingNumber.toUpperCase().includes(filterQuery.toUpperCase())
    );
  };
  const filteredData = applyFilter(invoiceList[selectedValue]);
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      let totalDueSum = 0;
      let costsSum = 0;
      let profitSum = 0;
      filteredData.forEach(rowData => {
        totalDueSum += isNaN(parseFloat(rowData.totalDue)) ? 0 : parseFloat(rowData.totalDue);
        costsSum += parseFloat(rowData.costs.replace("$", ""));
        profitSum += parseFloat(rowData.profit.replace("$", ""));
      });
      setGrandTotalDue(totalDueSum);
      setTotalCosts(costsSum);
      setTotalProfit(profitSum);
    } else {
      setGrandTotalDue(0);
      setTotalCosts(0);
      setTotalProfit(0);
    }
  }, [filteredData], invoiceList);
  const handleSearch = (query) => {
    setFilterQuery(query);
  };
  const [unbilledinvoices, setunbilledinvoices] = useState({
    items: [],
  });
  const [billedinvoices, setbilledinvoices] = useState({
    items: [],
  });
  const [allinvoices, setallinvoices] = useState({
    items: [],
  });
  const [billedandpaidinvoices, setbilledandpaidinvoices] = useState({
    items: [],
  });
  const [billednotpaidinvoices, setbillednotpaidinvoices] = useState({
    items: [],
  });
  const [perms, setPerms] = useState(false);
  axios.defaults.withCredentials = true;
  const getInvoicesByDate = () => {
    try {
      if (startDate !== null && endDate !== null) {
        axios
          .post(`${BASE_URL}/billing/getInvoicesByDate`, {
            start: formatDate(startDate),
            end: formatDate(endDate),
          })
          .then((response) => {
            const totalsLists = {
              all: response.data.totalsall,
              bap: response.data.totalsbap,
              bnp: response.data.totalsbnp,
              billed: response.data.totalsbilled,
              unbilled: response.data.totalsunbilled,
            };
            setTotalsList(totalsLists)
            console.dir(formatDate(startDate))
            console.dir(formatDate(endDate))

            console.dir(totalsLists)

            // setGrandTotalDue(response.data.totalsall[2])
            // setTotalCosts(response.data.totalsall[1])
            // setTotalProfit(response.data.totalsall[0])
            const invoiceLists = {
              all: [],
              bap: [],
              bnp: [],
              billed: [],
              unbilled: [],
            };

            const checkData = response.data.check;
            const unbilledData = response.data.unbilled
            const unbilledLength = unbilledData.length
            const checkLength = checkData.length;

            const tempAllInvoices = [];
            const tempBilledAndPaidInvoices = [];
            const tempBilledNotPaidInvoices = [];

            for (let x = 0; x < checkLength; x += 14) {
              const customer = checkData[x].trim().toLowerCase();
              const consignee = checkData[x + 1].trim().toLowerCase();
              const shipper = checkData[x + 2].trim().toLowerCase();
              const invoiceNumber = checkData[x + 3];
              const container = checkData[x + 4].trim().toLowerCase();
              const dateIn = formatDate(checkData[x + 5]);
              const dateOut = formatDate(checkData[x + 6]);
              const bookingNumber = checkData[x + 7].trim().toLowerCase();
              const paid = checkData[x + 8];
              const totalDue = checkData[x + 9].trim();
              const billedDate = formatDate(checkData[x + 10]);
              const costs = checkData[x + 11];
              const profit = checkData[x + 12];
              const remaining = checkData[x + 13];

              const invoiceOBJ = {
                customer,
                consignee,
                shipper,
                invoiceNumber,
                container,
                date_In: dateIn,
                date_Out: dateOut,
                bookingNumber,
                paid,
                totalDue,
                billedDate,
                costs,
                profit,
                remaining
              };

              if (paid !== "true" && paid !== "1") {
                invoiceOBJ.paid = 0;
              } else if (paid === "true") {
                invoiceOBJ.paid = 1;
              }

              if (billedDate !== "" && billedDate !== "undefined-undefined-undefined") {
                invoiceLists.billed.push(invoiceOBJ);
              }

              if (billedDate !== "" && billedDate !== null && billedDate !== "undefined-undefined-undefined" && invoiceOBJ.paid === 1) {
                invoiceLists.bap.push(invoiceOBJ);
              }

              if (billedDate !== "" && billedDate !== null && billedDate !== "undefined-undefined-undefined" && invoiceOBJ.paid !== 1) {
                invoiceLists.bnp.push(invoiceOBJ);
              }
              invoiceLists.all.push(invoiceOBJ);
            }
            for (let x = 0; x < unbilledLength; x += 14) {
              const customer = unbilledData[x].trim().toLowerCase();
              const consignee = unbilledData[x + 1].trim().toLowerCase();
              const shipper = unbilledData[x + 2].trim().toLowerCase();
              const invoiceNumber = unbilledData[x + 3];
              const container = unbilledData[x + 4].trim().toLowerCase();
              const dateIn = formatDate(unbilledData[x + 5]);
              const dateOut = formatDate(unbilledData[x + 6]);
              const bookingNumber = unbilledData[x + 7].trim().toLowerCase();
              const paid = 0;
              const totalDue = unbilledData[x + 9].trim();
              const billedDate = "";
              const costs = unbilledData[x + 11];
              const profit = unbilledData[x + 12];
              const remaining = checkData[x + 13];
              console.log(paid)

              const invoiceOBJ = {
                customer,
                consignee,
                shipper,
                invoiceNumber,
                container,
                date_In: dateIn,
                date_Out: dateOut,
                bookingNumber,
                paid,
                totalDue,
                billedDate,
                costs,
                profit,
                remaining
              };
              
              invoiceLists.unbilled.push(invoiceOBJ);
              
              invoiceLists.all.push(invoiceOBJ);
            }

            setbilledinvoices((prevBilledInvoices) => ({
              ...prevBilledInvoices,
              items: prevBilledInvoices.items.concat(invoiceLists.billed),
            }));

            setunbilledinvoices((prevUnbilledInvoices) => ({
              ...prevUnbilledInvoices,
              items: prevUnbilledInvoices.items.concat(invoiceLists.unbilled),
            }));

            setbilledandpaidinvoices((prevBilledAndPaidInvoices) => ({
              ...prevBilledAndPaidInvoices,
              items: prevBilledAndPaidInvoices.items.concat(invoiceLists.bap),
            }));

            setbillednotpaidinvoices((prevBilledNotPaidInvoices) => ({
              ...prevBilledNotPaidInvoices,
              items: prevBilledNotPaidInvoices.items.concat(invoiceLists.bnp),
            }));
            setallinvoices((prevAllInvoices) => ({
              ...prevAllInvoices,
              items: prevAllInvoices.items.concat(invoiceLists.all),
            }));
            setInvoiceList(invoiceLists);
          });
      } else {
        alert("please enter a beginning and end date");
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.billing === "true" ||
              response.data.permissions.billing === "1"
            ) {
              setPerms(true);
            }
          } else {
            window.location = "/";
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  const formatDate = (date) => {
    if (date !== undefined) {
      let splitDate = String(date).split(" ");
      const monthMap = {
        "jan": 1,
        "feb": 2,
        "mar": 3,
        "apr": 4,
        "may": 5,
        "jun": 6,
        "jul": 7,
        "aug": 8,
        "sep": 9,
        "oct": 10,
        "nov": 11,
        "dec": 12
      };
      let returnDate = monthMap[splitDate[1].toLowerCase()] + "/" + splitDate[2] + "/" + splitDate[3];
      if (returnDate === "undefined-undefined-undefined") {
        returnDate = "";
      }
      return returnDate;
    } else {
      return null;
    }
  };
  const handleChange = (event) => {
    let currentTotal = 0;
    // if (typeof invoiceList[event.target.value] !== "undefined") {
    //   invoiceList[event.target.value].forEach((element) => {
    //     currentTotal += Number(element.totalDue);
    //   });
    // }
    // setGrandTotalDue(totalsList[event.target.value][2])
    // setTotalCosts(totalsList[event.target.value][1])
    // setTotalProfit(totalsList[event.target.value][0])
    setSelectedValue(event.target.value);
  };
  const formatSelectedValue = (selectedValue) => {
    if (selectedValue === "all") {
      return "All Invoices"
    } else if (selectedValue === "unbilled") {
      return "Unbilled"
    } else if (selectedValue === "billed") {
      return "Billed"
    } else if (selectedValue === "bnp") {
      return "Billed Not Paid"
    } else if (selectedValue === "bap") {
      return "Billed and Paid"
    }
  };
  const MyDocument = ({ page }) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{fullCompanyName}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle3}>{formatSelectedValue(selectedValue)}        TotalDue: {formattedGrandTotalDue}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.weight}>Cust</Text>
          <Text style={styles.weight}>Cons</Text>
          <Text style={styles.weight}>Ship</Text>
          <Text style={styles.weight}>Invo#</Text>
          <Text style={styles.weight2}>Cont#</Text>
          <Text style={styles.weight2}>Booking#</Text>
          <Text style={styles.weight}>TotalDue</Text>
        </View>
        {InvoiceTableRowInfo(page)}
      </Page>
    </Document>
  );
  const InvoiceTableRowInfo = (page) => {
    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let currentPageData = filteredData;
    if(currentPageData === undefined){
      console.log("R.I.P")
    } else{
      currentPageData = filteredData.slice(startIndex, endIndex);
      return (
        <Fragment>
          {InvoiceTableRows(currentPageData)}
        </Fragment>
      );
    }
  };
  const InvoiceTableRows = (items) => {
    const rows = items.map((item) => (
      <View style={styles.row}>
        <Text style={styles.weight}>{item.customer.toUpperCase()}</Text>
        <Text style={styles.weight}>{item.consignee.toUpperCase()}</Text>
        <Text style={styles.weight}>{item.shipper.toUpperCase()}</Text>
        <Text style={styles.weight}>{item.invoiceNumber.toUpperCase()}</Text>
        <Text style={styles.weight2}>{item.container.toUpperCase()}</Text>
        <Text style={styles.weight2}>{item.bookingNumber.toUpperCase()}</Text>
        <Text style={styles.weight}>${item.totalDue}</Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    page2: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    titleContainer: {
      flexDirection: "row",
      marginTop: 12,
    },
    reportTitle: {
      color: "#61dafb",
      letterSpacing: 4,
      fontSize: 25,
      textAlign: "center",
      textTransform: "uppercase",
    },
    reportTitle2: {
      color: "#61dafb",
      letterSpacing: 2,
      fontSize: 18,
      textAlign: "center",
      textTransform: "uppercase",
    },
    reportTitle3: {
      letterSpacing: 2,
      fontSize: 18,
      textAlign: "right",
      textTransform: "uppercase",
    },
    remitlabel: {
      width: "100%",
    },
    label: {
      width: "33%",
      borderBottomColor: "#bff0fd",
      borderTopColor: "#bff0fd",
      borderLeftColor: "#bff0fd",
      borderRightColor: "#bff0fd",
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    },
    direction: {
      flexDirection: "row",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      backgroundColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
    },
    description: {
      width: "50%",
      textAlign: "left",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      paddingLeft: 8,
    },
    weight: {
      width: "10%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "left",
      paddingRight: 8,
    },
    weight2: {
      width: "25%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "left",
      paddingRight: 8,
    },
    charges: {
      width: "25%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "right",
      paddingRight: 8,
    },
    amount: {
      width: "15%",
      borderRightColor: "#90e5fc",
      textAlign: "left",
      paddingRight: 8,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontStyle: "bold",
    },
    footerTitleContainer: {
      flexDirection: "row",
      textAlign: "center",
      marginTop: 12,
    },
    footerReportTitle: {
      fontSize: 12,
      textAlign: "center",
      textTransform: "uppercase",
    },
  });
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Grid spacing={1} justify="center">
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            ></link>
            <Grid
              direction="row"
              xs={12}
              sm={12}
            >
              <Typography component="h1" variant="h5">
                Billing Page
              </Typography>
              <Grid 
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      variant="inline"
                      inputFormat="MM/dd/yyyy"
                      label="Start Date"
                      size="small"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          style={{ width: 150, marginRight: 5, marginTop: 10 }}
                        />
                      )}
                    ></DesktopDatePicker>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      variant="inline"
                      inputFormat="MM/dd/yyyy"
                      label="End Date"
                      size="small"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          style={{ width: 150, marginRight: 5, marginTop: 10 }}
                        />
                      )}
                    ></DesktopDatePicker>
                  </LocalizationProvider>
                  <Button
                    id="searchDriver"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={getInvoicesByDate}
                    style={buttonStyle}
                    // style={{ marginTop: '12px' }}

                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                  <RadioGroup row value={selectedValue} onChange={handleChange}>
                    <FormControlLabel
                      value="unbilled"
                      control={<Radio />}
                      label="Unbilled"
                    />
                    <FormControlLabel
                      value="billed"
                      control={<Radio />}
                      label="Billed"
                    />
                    <FormControlLabel
                      value="bnp"
                      control={<Radio />}
                      label="Billed Not Paid"
                    />
                    <FormControlLabel
                      value="bap"
                      control={<Radio />}
                      label="Billed and Paid"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All Invoices"
                    />
                  </RadioGroup>
                </FormControl>
                </Grid>
                <Grid item xs={2/3}>
                <TextField
                  id="GrandTotalDue"
                  label="Grand Total Due"
                  name="GrandTotalDue"
                  size="small"
                  value={formattedGrandTotalDue}
                  style={{ marginRight: 5, marginTop: 10 }}
                  autoComplete="off"
                  inputProps={{ readOnly: true }}
                ></TextField>
                </Grid>
                <Grid item xs={2/3}>
                <TextField
                  id="TotalCosts"
                  label="Total Costs"
                  name="TotalCosts"
                  size="small"
                  value={formattedTotalCosts}
                  style={{ marginRight: 5, marginTop: 10 }}
                  autoComplete="off"
                  inputProps={{ readOnly: true }}
                ></TextField>
                </Grid>
                <Grid item xs={2/3}>
                <TextField
                  id="TotalProfit"
                  label="Total Profit"
                  name="TotalProfit"
                  size="small"
                  value={formattedTotalProfit}
                  style={{ marginRight: 5, marginTop: 10 }}
                  autoComplete="off"
                  inputProps={{ readOnly: true }}
                ></TextField>
                </Grid>
                <Grid item xs={3}>
                  <PDFDownloadLink
                    document={<MyDocument page={currentPage} />}
                    fileName={`Billing_Page_${currentPage + 1}`}
                  >
                    {({ loading }) =>
                      <Button
                        variant="contained"
                        color="primary"
                        style={buttonStyle} 
                        disabled={loading} 
                      >
                        {loading ? "Loading Document..." : "Download"}
                      </Button>
                    }
                  </PDFDownloadLink>
                    <Button
                      disabled={currentPage === 0}
                      onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                    >
                      Previous Page
                    </Button>
                    <Button
                      disabled={currentPage === totalPages - 1}
                      onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                    >
                      Next Page
                    </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  icons={tableIcons}
                  data={filteredData}
                  columns={[
                    { field: "customer", title: "cust", render: rowData => rowData.customer.toUpperCase() },
                    { field: "consignee", title: "cons", render: rowData => rowData.consignee.toUpperCase() },
                    { field: "shipper", title: "ship", render: rowData => rowData.shipper.toUpperCase() },
                    { field: "invoiceNumber", title: "invoice", render: rowData => rowData.invoiceNumber.toUpperCase() },
                    { field: "container", title: "container", render: rowData => rowData.container.toUpperCase() },
                    { field: "date_In", title: "date_out" },
                    { field: "date_Out", title: "date_in" },
                    { field: "bookingNumber", title: "booking#", render: rowData => rowData.bookingNumber.toUpperCase() },
                    { field: "paid", title: "paid", type: "boolean" },
                    { field: "totalDue", title: "total due", render: rowData => `$${rowData.totalDue}` },
                    { field: "billedDate", title: "billed Date" },
                    { field: "costs", title: "costs", render: rowData => `$${rowData.costs}` },
                    { field: "profit", title: "profit", render: rowData => `$${rowData.profit}` },
                    { field: "remaining", title: "remaining", render: rowData => `$${rowData.profit}` },
                  ]}
                  options={{
                    paging: true,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [100, 200, 500],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      backgroundColor: "#DEF3FA",
                      color: "Black",
                      borderRight: "1px solid",
                      width: 100,
                      maxWidth: 100,
                    },
                    cellStyle: {
                      width: 100,
                      maxWidth: 100,
                    },
                    sorting: true,
                    search: true,
                    searchFieldAlignment: 'left',
                  }}
                  onSearchChange={(query) => {
                    handleSearch(query)
                    console.log(query);
                  }}
                  onOrderChange={(orderBy, orderDirection) => {
                    // Create a copy of filteredData and sort it
                    const orderByToString = {
                      0: 'customer',
                      1: 'consignee',
                      2: 'shipper',
                      3: 'invoiceNumber',
                      4: 'container',
                      5: 'date_out',
                      6: 'date_In',
                      7: 'bookingNumber',
                      8: 'paid',
                      9: 'totalDue',
                      10: 'billedDate',
                      11: 'costs',
                      12: 'profit',
                      13: 'remaining'
                    };
                    const mappedOrderBy = orderByToString[orderBy] || '';
                    const sortedData = [...filteredData].sort((a, b) => {
                      console.log("orderBy, ", orderBy)
                      console.log("mappedOrderBy, ", mappedOrderBy)
                      const aValue = a[mappedOrderBy] || ''; 
                      const bValue = b[mappedOrderBy] || '';
                      if (orderDirection === 'asc') {
                        return aValue.localeCompare(bValue);
                      } else {
                        return bValue.localeCompare(aValue);
                      }
                    });
                    console.dir(sortedData)
                    // Update the state with the sorted data
                    setInvoiceList((prevInvoiceList) => ({
                      ...prevInvoiceList,
                      [selectedValue]: sortedData, // Update the sorted data for the selected value
                    }));
                    console.dir(sortedData)
                  }}
                />
                <TablePagination
                  rowsPerPageOptions={[100, 200, 500]}
                  component="div"
                  count={(invoiceList[selectedValue] || []).length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
